import { Component, HostListener, Injector, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { AlertService } from './@pages/services/alert.service';
import { LoginService } from './@pages/services/login.service';
import { OnInit, OnDestroy } from '@angular/core';
import { NgcCookieConsentService, NgcInitializeEvent, NgcNoCookieLawEvent, NgcStatusChangeEvent } from 'ngx-cookieconsent';
import { Subscription }   from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { NavigationEnd } from '@angular/router';	// gayuh added for Google Analytics 2021.12.06
import { filter } from 'rxjs/operators';
import { MixpanelService } from './@pages/services/mixpanel.service';

declare let gtag: Function;	// gayuh added for Google Analytics 2021.12.06

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy  {
  title = 'app works!';
  userActivity;
  userInactive: Subject<any> = new Subject();
  private popupOpenSubscription: Subscription;
  private popupCloseSubscription: Subscription;
  private initializeSubscription: Subscription;
  private statusChangeSubscription: Subscription;
  private revokeChoiceSubscription: Subscription;
  private noCookieLawSubscription: Subscription;
 
  constructor(private router: Router, private loginService: LoginService, 
    private alertService: AlertService, private ccService: NgcCookieConsentService,
     private mixpanelService: MixpanelService) { 
     }
  ngOnInit() {
  this.mixpanelService.init()
	this.setUpAnalytics();	// gayuh added for Google Analytics 2021.12.06

    
    // this.translateService.addLangs(['en', 'fr']);
    // this.translateService.setDefaultLang('en');
 
    // const browserLang = this.translateService.getBrowserLang();
    // this.translateService.use(browserLang.match(/en|fr/) ? browserLang : 'en');
 

      
    // subscribe to cookieconsent observables to react to main events
    this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
      });
 
    this.popupCloseSubscription = this.ccService.popupClose$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
      });
 
    this.initializeSubscription = this.ccService.initialize$.subscribe(
      (event: NgcInitializeEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
      });
 
    this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
      (event: NgcStatusChangeEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
      });
 
    this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
      });
 
      this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe(
      (event: NgcNoCookieLawEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
      });
    
    // window.addEventListener('storage', (event) => {
    //   if (event.storageArea == localStorage) {
    //     const user = JSON.parse(localStorage.getItem('currentUser'));
    //     if (!user) {
    //       //this.router.navigate(['/landing/home']); 
    //       if (window.location.host.includes('localhost')) {
    //         window.location.href = 'http://' + window.location.host + '/landing/home'
    //       } else {
    //         window.location.href = 'https://' + window.location.host + '/landing/home'
    //       }
    //     }
    //   }
    // }, false);
    this.setTimeout();
    this.userInactive.subscribe(() => {
      this.logout();
    });
   

  }

	// gayuh added for Google Analytics 2021.12.06
	setUpAnalytics() {
		this.router.events.pipe(filter(event => event instanceof NavigationEnd))
			.subscribe((event: NavigationEnd) => {
				if (event.urlAfterRedirects.startsWith("/landing"))  {
					// Google Analytics for company homepage
					gtag('config', 'UA-231538833-1',
						{
							page_path: event.urlAfterRedirects
						}
					);
				}
				else if (event.urlAfterRedirects.startsWith("/business-crm-software"))  {
					// Google Analytics for CRM homepage
					gtag('config', 'UA-231538833-1',
						{
							page_path: event.urlAfterRedirects
						}
					);
				}
				else if (event.urlAfterRedirects.startsWith("/marketing-crm-software"))  {
					// Google Analytics for Marketing homepage
					gtag('config', 'UA-231538833-1',
						{
							page_path: event.urlAfterRedirects
						}
					);
				}
				else if (event.urlAfterRedirects.startsWith("/sales-management-crm-software"))  {
					// Google Analytics for Sales homepage
					gtag('config', 'UA-231538833-1',
						{
							page_path: event.urlAfterRedirects
						}
					);
				}
				else if (event.urlAfterRedirects.startsWith("/customer-service-support-software"))  {
					// Google Analytics for Support homepage
					gtag('config', 'UA-231538833-1',
						{
							page_path: event.urlAfterRedirects
						}
					);
				}
			});
	}

  ngOnDestroy() {
    // unsubscribe to cookieconsent observables to prevent memory leaks
    this.popupOpenSubscription.unsubscribe();
    this.popupCloseSubscription.unsubscribe();
    this.initializeSubscription.unsubscribe();
    this.statusChangeSubscription.unsubscribe();
    this.revokeChoiceSubscription.unsubscribe();
    this.noCookieLawSubscription.unsubscribe();
  }

  logout() {
    this.loginService.logout();
    setTimeout(() => {
      this.alertService.info('Session Timeout: Your session is idle for more than 2 minutes.')
    }, 5000);

  }

  setTimeout() {
    this.userActivity = setTimeout(() => {
      if (localStorage.getItem('currentUser')) {
        this.userInactive.next(undefined);
      }
    }, 43200000);
  }

  @HostListener('window:mousemove') refreshUserState() {
    clearTimeout(this.userActivity);
    this.setTimeout();
  }
}
