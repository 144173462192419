<pg-header boxed="true">
  <div class="header-inner header-md-height">
    <!-- START MOBILE SIDEBAR TOGGLE -->
    <a href="javascript:void(0);" class="btn-icon-link header-icon toggle-sidebar d-lg-none"
      (click)="toggleHorizontalMenuMobile()">
      <i class="pg-icon">menu</i>
    </a>
    <!-- END MOBILE SIDEBAR TOGGLE -->
    <div class="">
      <!-- START EMAIL MOBILE TOGGLE -->
      <a href="javascript:void(0);" class="toggle-secondary-sidebar align-items-center"
        (click)="toggleSecondarySideBar()" *ngIf="_layoutOption === 'email'">
        <span class="d-flex align-items-center">
          Inbox <span class="text-info">(12)</span> <span class="pg-icon">drop_down</span>
        </span>
      </a>
      <!-- END EMAIL MOBILE TOGGLE -->

      <div class="brand inline align-self-end" style="vertical-align: super !important;">
        <div *ngIf="userOrganizationPicture">
          <img src="{{userOrganizationPicture}}" src1x="assets/home/assets/images/logowhite.png"
            src2x="assets/home/assets/images/logowhite.png" alt="logo" pgRetina width="118" height="22"
            (click)="goToDashboard()" style="cursor: pointer;">
        </div>
        <div *ngIf="!userOrganizationPicture">
          <img src="assets/home/assets/images/logowhite.png" src1x="assets/home/assets/images/logowhite.png"
            src2x="assets/home/assets/images/logowhite.png" alt="logo" pgRetina width="118" height="22"
            (click)="goToDashboard()" style="cursor: pointer;">
        </div>
      </div>

      <!-- START NOTIFICATION LIST -->
      <ul class="d-lg-inline-block d-none notification-list no-margin  b-grey b-l b-r no-style p-l-20 p-r-20">
        <li class="p-r-10 inline">

          <div class="dropdown" placement="bottom" tooltip="Notifications" dropdown>
            <a href="javascript:;" id="notification-center" class="header-icon btn-icon-link" dropdownToggle
              (click)="notificationIconClick()">
              <i class="pg-icon">world</i>
              <span *ngIf="showNotificationBubble" class="bubble"></span>
            </a>
            <div class="dropdown-menu notification-toggle" role="menu" aria-labelledby="notification-center"
              *dropdownMenu>
              <!-- START Notification -->
              <div class="notification-panel">
                <!-- START Notification Body-->
                <div class="notification-body scrollable" *ngIf="notifications && notifications.usernotifications">
                  <!-- START Notification Item-->
                  <div class="notification-item unread clearfix" *ngFor="let report of notifications.usernotifications">
                    <!-- START Notification Item-->
                    <div class="heading open" *ngIf="report?.read === '0'">
                      <a href="#" class="text-complete pull-left d-flex align-items-center">
                        <i class="pg-icon m-r-10">map</i>
                        <span class="bold">{{report.createdUserDetails.firstName}}
                          {{report.createdUserDetails.lastName}} is assigned to you</span>
                        <!-- <span class="fs-12 m-l-10">David Nester</span> -->
                      </a>
                      <div class="pull-right">
                        <div class="thumbnail-wrapper d16 circular inline m-t-15 m-r-10 toggle-more-details">
                          <div><i class="pg-icon">chevron_down</i>
                          </div>
                        </div>
                        <span class=" time">{{report.createdAt | timeAgo}}</span>
                      </div>
                      <div class="more-details">
                        <div class="more-details-inner" style="padding-top: 0px !important;">
                          <h5 class="semi-bold fs-16">{{report.name}}</h5>
                          <small class="pull-right">
                            <p class="d-flex" *ngIf="report.type && report.type.includes('TASK')">
                              <i class="pg-icon m-r-5">comment</i><span
                                class="label label-success activity-note">{{report.type.replace('_','
                                ')}}</span>
                            </p>
                            <p class="d-flex" *ngIf="report.type && report.type.includes('EVENT')">
                              <i class="pg-icon m-r-5">comment</i><span
                                class="label label-success activity-note">{{report.type.replace('_','
                                ')}}</span>
                            </p>
                            <p class="d-flex" *ngIf="report.type && report.type === 'DEAL'">
                              <i class="pg-icon m-r-5">comment</i><span
                                class="label label-success activity-note">{{report.type}}</span>
                            </p>
                          </small>
                          <p class="small hint-text">
                            {{report.description}}
                          </p>

                        </div>
                      </div>
                    </div>
                    <!-- END Notification Item-->
                    <!-- START Notification Item Right Side-->
                    <div class="option" data-toggle="tooltip" data-placement="left" title="mark as read"
                      *ngIf="report?.read === '0'">
                      <a (click)="markAsRead(report)" class="mark"></a>
                    </div>
                    <!-- END Notification Item Right Side-->

                  </div>
                  <!-- START Notification Body-->
                  <!-- START Notification Item-->
                  <div class="notification-item  clearfix" *ngFor="let report of notifications.usernotifications">
                    <div class="heading open" *ngIf="report?.read === '1'">
                      <div class="pull-right">
                        <span class=" time">{{report.createdAt | timeAgo}}</span>
                      </div>
                      <a href="#" class="text-complete">
                        <span class="bold">{{report.createdUserDetails.firstName}}
                          {{report.createdUserDetails.lastName}} is assigned to you</span>
                      </a>
                      <p>{{report.name}}</p>
                      <small class="pull-right">
                        <span class="d-flex" *ngIf="report.type && report.type.includes('TASK')">
                          <i class="pg-icon m-r-5">comment</i><span
                            class="label label-success report-note">{{report.type.replace('_','
                            ')}}</span>
                        </span>
                        <span class="d-flex" *ngIf="report.type && report.type.includes('EVENT')">
                          <i class="pg-icon m-r-5">comment</i><span
                            class="label label-success report-note">{{report.type.replace('_','
                            ')}}</span>
                        </span>
                        <span *ngIf="report.type && report.type === 'DEAL'">
                          <i class="pg-icon m-r-5">comment</i><span
                            class="label label-success report-note">{{report.type}}</span>
                        </span>
                      </small>
                      <p>{{report.description}}</p>
                    </div>
                    <!-- START Notification Item Right Side-->
                    <div class="option">
                      <a href="#" class="mark"></a>
                    </div>
                  </div>
                  <!-- END Notification Item-->
                  <!-- START Notification Item-->
                </div>
                <!-- END Notification Body-->
                <!-- START Notification Footer-->
                <div class="notification-footer text-center">
                  <a (click)="usersNav()" class="">Read all notifications</a>
                  <a data-toggle="refresh" class="portlet-refresh text-black pull-right" href="#">
                    <i class="pg-refresh_new"></i>
                  </a>
                </div>
                <!-- START Notification Footer-->
              </div>
              <!-- END Notification -->
            </div>
            <!-- END Notification Dropdown -->
          </div>
        </li>
      </ul>
      <!-- END NOTIFICATIONS LIST -->
      <!-- <a href="javascript:void(0)" class="search-link d-lg-inline-block d-none" (click)="openSearch($event)">
        <i class="pg-icon">search</i>Type anywhere to <span class="bold">search</span>
      </a> -->
    </div>
    <div class="d-flex align-items-center">

      <span class="mx-2" *ngIf="userInfo"><b>{{userInfo.firstName}} {{userInfo.lastName}}</b></span>
      <div class="dropdown pull-right d-lg-block d-none" dropdown>

        <button class="profile-dropdown-toggle" type="button" dropdownToggle id="profileDropdown"
          aria-label="profile dropdown">
          <span class="thumbnail-wrapper d32 circular inline">
            <img referrerpolicy="no-referrer" [src]="userProfilePictureThumbPath || userProfilePicturePath" alt=""
              *ngIf="userProfilePictureThumbPath || userProfilePicturePath" style="object-fit: cover;">
            <img src="assets/home/assets/images/userpic.jpg" alt="" width="32" height="32"
              *ngIf="!userProfilePictureThumbPath && !userProfilePicturePath">
          </span>
        </button>
        <div class="dropdown-menu dropdown-menu-right profile-dropdown" *dropdownMenu role="menu">

          <a [routerLink]="['/users/details']" [queryParams]="{userId: userId}">Profile</a>
          <a (click)="goToLogout()" class="dropdown-item">Logout</a>

        </div>
      </div>

      <!--  <a href="javascript:void(0)" class="header-icon btn-icon-link m-l-5 sm-no-margin d-inline-block btn-icon-link"
        (click)="openQuickView($event)">
        <i class="pg-icon">menu_add</i>
      </a> -->
    </div>
  </div>
  <!-- <div class="header-inner justify-content-start header-lg-height title-bar">
    <div class="brand inline align-self-end">
      <img src="assets/home/assets/images/logowhite.png" alt="logo" pgRetina
      src1x="assets/home/assets/images/logowhite.png" src2x="assets/home/assets/images/logowhite.png" width="118"
      height="22">
    </div>
    <h2 class="page-title align-self-end">
      {{_pageTitle}}
    </h2>
  </div> -->
  <pg-horizontal-menu [Items]="menuItems" HideExtra="0">
    <ng-template #mobileSidebarFooter>
      <a href="javascript:void(0)" class="search-link d-flex justify-content-between align-items-center d-lg-none"
        (click)="openSearch($event)">Tap here to search <i class="pg pg-search float-right"></i></a>
    </ng-template>
  </pg-horizontal-menu>
</pg-header>
<div class="page-container">
  <!-- START PAGE CONTENT WRAPPER -->
  <div class="page-content-wrapper {{_pageContainerClass}}">
    <!-- START PAGE CONTENT -->
    <div class="content {{_contentClass}}">
      <router-outlet></router-outlet>
    </div>
    <!-- END PAGE CONTENT -->
    <!-- START COPYRIGHT -->
    <!-- START CONTAINER FLUID -->
    <!-- <ng-template [ngIf]="_footer">
      <div class=" container   container-fixed-lg footer">
        <div class="copyright sm-text-center">
          <p class="small no-margin pull-left sm-pull-reset">
            <span class="hint-text">Copyright &copy; 2017 </span>
            &nbsp;<span class="font-montserrat">REVOX</span>.&nbsp;
            <span class="hint-text">All rights reserved. </span>
            <span class="sm-block"><a href="javascript:void(0)" class="m-l-10 m-r-10">Terms of use</a> <span
                class="muted">|</span> <a href="javascript:void(0)" class="m-l-10">Privacy Policy</a></span>
          </p>
          <p class="small no-margin pull-right sm-pull-reset">
            Hand-crafted <span class="hint-text">&amp; made with Love</span>
          </p>
          <div class="clearfix"></div>
        </div>
      </div>
    </ng-template> -->
    <!-- END COPYRIGHT -->
  </div>
  <!-- END PAGE CONTENT WRAPPER -->
</div>
<!-- END PAGE CONTAINER -->
<!-- <app-search-overlay></app-search-overlay> -->
<app-quickview></app-quickview>