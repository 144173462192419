import { Injectable } from '@angular/core';
// import * as ExcelJS from 'exceljs';
import * as ExcelJS from 'exceljs/dist/exceljs.min.js'
import * as FileSaver from 'file-saver';

@Injectable()
export class ExcelService {
    constructor() {}

    static toExportFileName(excelFileName: string): string {
        return `${excelFileName}_export_${new Date().getTime()}.csv`;
    }

    public exportAsExcelFile(json: any[], excelFileName: string): void {

        function Dateformatting(timestamp) {
            const date = new Date(timestamp);
            return date.toLocaleString();
        }
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Sheet1');

        const headers = [
            'Name', 'Type', 'Description', 
            'Created By', 'Updated By', 'Created At', 'Updated At'
        ];
        
        const headerRow = worksheet.addRow(headers);
        headerRow.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'a6a6a6' }
        };

        const columnWidths = {};


        for (const item of json) {
            const rowData = {
                Name: item.name,
                Type: item.type,
                Description: item.description,
                CreatedBy: '',
                UpdatedBy: '',
                CreatedAt: Dateformatting(item.createdAt),
                UpdatedAt: Dateformatting(item.updatedAt)
            };
            if (item.createdUserDetails && item.createdUserDetails.firstName) {
                rowData.CreatedBy = `${item.createdUserDetails.firstName} ${item.createdUserDetails.lastName || ''}`;
            }
            if (item.updatedUserDetails && item.updatedUserDetails.firstName) {
                rowData.UpdatedBy = `${item.updatedUserDetails.firstName} ${item.updatedUserDetails.lastName || ''}`;
            }
            const row = worksheet.addRow(Object.values(rowData));

        row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
            const column = worksheet.getColumn(colNumber);
            const cellValue = cell.text;
            const cellWidth = cellValue.length + 2;
            columnWidths[colNumber] = Math.max(columnWidths[colNumber] || 0, cellWidth);
        });
            
        }

        worksheet.columns.forEach((column, colNumber) => {
            if (colNumber === 1 || colNumber === 3) {
                column.width = Math.min(columnWidths[colNumber + 1], 25);
            } else {
                column.width = columnWidths[colNumber + 1];
            }
        });

        workbook.xlsx.writeBuffer().then((buffer) => {
            const data: Blob = new Blob([buffer], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            });
            FileSaver.saveAs(data, ExcelService.toExportFileName(excelFileName));
        });
    }
}