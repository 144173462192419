import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, filter, switchMap } from 'rxjs/operators';
import { AppConstants } from '../utilities/AppConstants';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' }) 
export class LoginService {

  loginDetails: any = {}

  constructor(public http: HttpClient, public cookieService: CookieService, private router: Router) { }

  getCurrentUser(){
    const currentUserData:any = localStorage.getItem('currentUser')
    if(currentUserData){
      return JSON.parse(currentUserData)
    }else{
      return null
    }
  }

  login(data): Observable<any> {
    const loginUrl = AppConstants.LOGIN_URL + '';
    return this.http.post(loginUrl, data)
      .pipe(map(
        res => {
          localStorage.setItem('currentUser', JSON.stringify(res));
          this.cookieService.set('userSession', 'user Session created', 1 / 24);
          return res;
        },
        err => {
          
          
          return err;
        }
      ));
  }

  register(data): Observable<any> {
    
    
    const registerUrl = AppConstants.REGISTER_URL + '';
    
    
    
    return this.http.post(registerUrl, data)
      .pipe(map(
        res => {
          
         // localStorage.setItem('currentUser', JSON.stringify(res));
         // this.cookieService.set('userSession', 'user Session created', 1 / 24);
          return res;
        },
        err => {
          return err;
        }
      ));
  }

  logout(onlyClear = false) {
   localStorage.removeItem('currentUser');
   localStorage.removeItem('tenantId');
   localStorage.removeItem('allLinks');
   localStorage.removeItem('dealsItem');
   localStorage.removeItem('supportItem');
   localStorage.removeItem('accessroles');
   this.cookieService.deleteAll('userSession');
   if(!onlyClear){
     if(window.location.host.includes('localhost')) {
      //window.location.href = 'http://'+window.location.host+'/landing/home'
      window.location.href = 'http://'+window.location.host
       } else {
        window.location.href = 'https://'+window.location.host
      }
   }
  }

  forgotPassword(data): Observable<any> {
    const forgotPasswordUrl = AppConstants.FORGOT_PASSWORD_URL + '';
    return this.http.post(forgotPasswordUrl, data)
      .pipe(map(
        res => {
          
          return res;
        },
        err => {
          return err;
        }
      ));
  }

  resetToken(data): Observable<any> {
    const resetTokenUrl = AppConstants.RESET_TOKEN_URL + data;
    
    return this.http.get(resetTokenUrl, {})
      .pipe(map(
        res => {
          return res;
        },
        err => {
          return err;
        }
      ));
  }

  confirmPassword(data, token): Observable<any> {
    
    const confirmPasswordUrl = AppConstants.CONFIRM_PASSWORD_URL + token;

    return this.http.put(confirmPasswordUrl, data)
      .pipe(map(
        res => {
          return res;
        },
        err => {
          return err;
        }
      ));
  }
}