import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { RootLayout } from '../root/root.component';

declare var pg: any;
@Component({
  selector: 'executive-layout',
  templateUrl: './executive.component.html',
  styleUrls: ['./executive.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ExecutiveComponent extends RootLayout implements OnInit, OnDestroy {
  tenantId;
  isLoading;
  page = 1;
  limit = 4;
  userEmail;
  userId;
  today: number = Date.now();
  userDetails: any;
  tenantDetails: any;
  paths: Array<string> = [];
  profilePictureDetails;
  userProfilePicturePath;
  userProfilePictureThumbPath;
  activityReportsList;
  userInfo: any;
  userAcessInfo: any;
  showNotificationBubble = false;
  type: any;
  userAcessInfos: any
  notificationInterval: any;
  menuItems = [
    // {
    //   label: 'Dashboard',
    //   iconType: 'pg',
    //   iconName: 'home',
    //   toggle: 'close',
    //   submenu: [
    //     {
    //       label: 'Tickets',
    //       routerLink: '/ticketdashboard/view',
    //     },
    //     {
    //       label: 'Orders',
    //       routerLink: '/orderdashboard/view',
    //     },
    //   ],
    // },

    // {
    //   label: 'Workspace',
    //   routerLink: '/workspace/details',
    //   iconType: 'pg',
    //   iconName: 'layout',
    // },
    // {
    //   label: 'Contacts',
    //   iconType: 'pg',
    //   iconName: 'social',
    //   toggle: 'close',
    //   submenu: [
    //     {
    //       label: 'People',
    //       routerLink: '/contacts/list',
    //     },
    //     {
    //       label: 'Organizations',
    //       routerLink: '/organizations/list',
    //     },
    //   ],
    // },
    // {
    //   label: 'Activities',
    //   iconType: 'pg',
    //   iconName: 'social',
    //   toggle: 'close',
    //   submenu: [
    //     {
    //       label: 'All Activities',
    //       routerLink: '/activities/calendar',
    //     },
    //     {
    //       label: 'Tasks',
    //       routerLink: '/tasks/view',
    //     },
    //   ],
    // },
    {
      label: 'Marketing',
      iconType: 'pg',
      iconName: 'grid',
      toggle: 'close',
      submenu: [

        // {
        //   label: 'Email',
        //   routerLink: '/marketing/email',
        // },
        // {
        //   label: 'Forms',
        //   routerLink: '/marketing/forms',
        // },
        // {
        //   label: 'Landing pages',
        //   routerLink: '/marketing/landingpages',
        // },
        {
          label: 'Social Media',
          routerLink: '/socialmedia/library',
        },
        // {
        //   label: 'Campaigns',
        //   routerLink: '/marketing/campaigns',
        // },
        // {
        //   label: 'Files',
        //   routerLink: '/marketing/marketingfiles',
        // },
        // {
        //   label: 'SEO',
        //   iconType: 'pg',
        //   iconName: 'effects',
        //   toggle: 'close',
        //   routerLink: '',
        //   submenu: [
        //     {
        //       label: 'Backlink Checker',
        //       routerLink: '/backlinkchecker/list',
        //     },
        //     {
        //       label: 'Keyword Search',
        //       routerLink: '/keywordsearch/list',
        //     },
        //     {
        //       label: 'Website Audit',
        //       routerLink: '/websiteaudit/list',
        //     },
        //   ],
        // },
      ],
    },
    // {
    //   label: 'Reports',
    //   iconType: 'pg',
    //   iconName: 'flag',
    //   toggle: 'close',
    //   submenu: [
    //     {
    //       label: 'Activity Reports',
    //       routerLink: '/activityreports/list',
    //     },
    //     {
    //       label: 'Task Reports',
    //       routerLink: '/taskreports/list',
    //     },
    //     {
    //       label: 'Event Reports',
    //       routerLink: '/eventreports/list',
    //     },
    //     {
    //       label: 'Note Reports',
    //       routerLink: '/notereports/list',
    //     },
    //     {
    //       label: 'File Reports',
    //       routerLink: '/filereports/list',
    //     },
    //   ],
    // },
    {
      label: 'Settings',
      iconType: 'pg',
      iconName: 'grid',
      toggle: 'close',
      submenu: [
        {
          label: 'Users',
          routerLink: '/users/list',
        },
        // {
        //   label: 'Billing',
        //   routerLink: '/billing/wizard',
        // },
        // {
        //   label: 'Groups',
        //   routerLink: '/groups/list',
        // },
        // {
        //   label: 'Integrations',
        //   routerLink: '/integrations/list',
        // },
      ],
    },
    // {
    //   label: 'Automation',
    //   iconType: 'pg',
    //   iconName: 'grid',
    //   toggle: 'close',
    //   submenu: [
    //     {
    //       label: 'Sequence',
    //       routerLink: '/sequence/list',
    //     },
    //     {
    //       label: 'Workflow',
    //       routerLink: '/workflow/list',
    //     },
    //   ],
    // },
  ];
  userAcessToolInfo: any;
  invoices: any;
  userOrganizationPicture: any;
  ngOnInit() {
    this.userDetails = JSON.parse(localStorage.getItem('currentUser'));
    this.getUserPicture();
    this.loadUserDetails();
    this.userFileService.userProfilePic.subscribe((res) => {
      this.userProfilePicturePath = res;
    });
    this.userFileService.userProfilePicThumb.subscribe((res) => {
      this.userProfilePictureThumbPath = res;
    });
    this.userService.userDetailInfo.subscribe((res) => {
      this.userInfo = res;
    });
    this.userAccess.userAccessDetailInfo.subscribe((res) => {
      this.userAcessInfo = res;
    });

    pg.isHorizontalLayout = true;
    this.changeLayout('horizontal-menu');
    this.changeLayout('horizontal-app-menu');

    if (!this.userDetails) {
      if (window.location.host.includes('localhost')) {
        window.location.href = 'http://' + window.location.host + '/landing/home';
      } else {
        window.location.href = 'https://' + window.location.host + '/landing/home';
      }
    } else {
      this.userId = this.userDetails.id;

      this.tenantId = localStorage.getItem('tenantId');
      this.tenantDetails = this.userDetails.tenantDetails;

      this.profilePictureDetails = this.userDetails.profilePictureDetails;

      const parsedUrl = new URL(window.location.href);
      const baseUrl = parsedUrl.origin;
      this.userAccess.getUserAccess(this.userDetails.id, 'Invoices').subscribe(
        (res) => {
          console.log(res)
          this.invoices = res;
        },
      )

      this.userEmail = this.userDetails.email;
      this.menuItems.forEach((item: any) => {
        if (item.routerLink) this.paths.push(item.routerLink);
        if (item.submenu) {
          item.submenu.forEach((item) => {
            if (item.routerLink) {
              this.paths.push(item.routerLink);
            }
          });
        }
      });
      this.userAccess.getAllUserAccessQuery(this.userDetails.id).subscribe(
        (res) => {
          this.userAcessInfos = res.useralerts;
          let loadPermissions = {};
          for (let userAccess of this.userAcessInfos) {
            if (userAccess.type === 'SERVICE' && userAccess.access === 'OFF') {
              this.tenantDetails.isTotalCRM = false;
              if (JSON.parse(this.tenantDetails.isSupportandOrder) || JSON.parse(this.tenantDetails.isTotalCRM)) {
                this.loadSupportAndOrdersLinks();
              }
            } else {
              if (userAccess.type === 'SERVICE' && userAccess.access === 'ON') {
                if (JSON.parse(this.tenantDetails.isSupportandOrder) || JSON.parse(this.tenantDetails.isTotalCRM)) {
                  this.loadSupportAndOrdersLinks();
                }
              }
            }
            if (typeof (loadPermissions[userAccess.type]) == 'undefined') loadPermissions[userAccess.type] = [];
            loadPermissions[userAccess.type].push(userAccess);
          }
          this.roleService.addRoles(loadPermissions);
        })

      this.userAccess.getAllUserAccessTools(this.userDetails.id, this.page, this.limit, 'CRM').subscribe(
        (res) => {
          console.log(res);

          this.userAcessToolInfo = res.useraaccesstools;

          for (let userAccess of this.userAcessToolInfo) {
            if (userAccess.type === 'CRM' && userAccess.access === 'ON') {
              this.loadOnlyCrmLinks();
            }

          }
        })

      this.tenantDetailService.getTenantDetailDetails(this.tenantId).subscribe(res => {
        this.userOrganizationPicture = res.tenantImage;
      },
        err => {
          this.alert.error('Error occured: ' + err);
        });

      if (JSON.parse(this.tenantDetails.isSupportandOrder) || JSON.parse(this.tenantDetails.isTotalCRM)) {
        this.loadSupportAndOrdersLinks();
      }
      if (JSON.parse(this.tenantDetails.isOnlyCRM) || JSON.parse(this.tenantDetails.isTotalCRM)) {

        this.loadOnlyCrmLinks();
        // this.menuItems[0].submenu.splice(0, 0, {
        //   label: 'Deals',
        //   routerLink: '/dealdashboard/view',
        // });
      }

      this.getActivityReports(this.page, this.limit, this.tenantId);
      setTimeout(() => {
        this.activityReportsList = this.activityReports;
        let latestActivity;
        if (
          this.activityReportsList &&
          this.activityReportsList.finalList &&
          this.activityReportsList.finalList.activityreportss
        ) {
          latestActivity = this.activityReportsList.finalList.activityreportss[0];
        }

        const notificationClickedTime = localStorage.getItem('notificationClickTime');
        if (latestActivity) {
          if (!notificationClickedTime || new Date(latestActivity.updatedAt) > new Date(notificationClickedTime)) {
            this.showNotificationBubble = true;
          }
        }
      }, 6000);

      // this.notificationInterval = setInterval(() => {
      //   this.getUserNotifications(this.userDetails.id, this.page, this.limit);
      // }, 4000);
    }
    const allAvailableItems = [];
    for (const item of this.menuItems) {
      if ((<any>item).routerLink) {
        allAvailableItems.push((<any>item).routerLink);
      } else {
        for (const item1 of item.submenu) {
          allAvailableItems.push(item1.routerLink);
        }
      }
    }
    localStorage.setItem('allLinks', JSON.stringify(allAvailableItems));
  }

  getUserPicture() {
    this.userFileService
      .getAllUserFiles(this.userDetails.id, this.page, this.limit)
      .subscribe(
        (res) => {
          if (res[0]) {
            const userFileData = res[0].path;
            const userProfilePicture = { url: userFileData };
            this.isLoading = false;
            this.userProfilePicturePath = userProfilePicture.url;
            this.userFileService.userProfilePic.next(userProfilePicture.url);
            this.userFileService.userProfilePicThumb.next(res[0].thumbPath);
          }
        },
        (err) => { }
      );
  }

  updateUrl($event) {
    this.userProfilePicturePath = 'assets/home/assets/images/userpic.png';
  }

  goToLogout() {
    this.logout();
  }

  goToDashboard() {
    this.router.navigate(['/trailguidedashboard']);
  }

  searchKey(key) {
    let path: string;
    for (const item of this.paths) {
      if (item.includes(key)) {
        path = item;
        break;
      }
    }
    if (path === '' || path === undefined) return;
    this.router.navigate([path]);
  }

  loadOnlyCrmLinks() {
    const dealLink = {
      label: 'Sales',
      iconType: 'pg',
      iconName: 'card',
      toggle: 'close',
      submenu: [
        {
          label: 'Leads',
          routerLink: '/deals/view',
        },
        {
          label: 'Pipelines',
          routerLink: '/pipelines/list',
        },
      ],
    };
    let obj = this.menuItems.find(o => o.label === 'Sales');
    if (!obj) {
      // this.menuItems.splice(4, 0, dealLink);
      // localStorage.setItem('dealsItem', 'true');
    }
  }

  loadSupportAndOrdersLinks() {
    const supportLink = {
      label: 'Support',
      iconType: 'pg',
      iconName: 'clipboard',
      toggle: 'close',
      submenu: [
        {
          label: 'Channels',
          iconType: 'pg',
          iconName: 'effects',
          toggle: 'close',
          routerLink: '',
          submenu: [
            {
              label: 'Email',
              routerLink: '/risepathemail/list',
            },
            {
              label: 'Facebook',
              routerLink: '/risepathfacebook/list',
            },
            {
              label: 'Twitter',
              routerLink: '/risepathtwitter/list',
            },
            {
              label: 'Widgets',
              routerLink: '/widgets/list',
            },
          ],
        },
        {
          label: 'Tickets',
          routerLink: '/tickets/list',
        },
        {
          label: 'Orders',
          routerLink: '/orders/list',
        },
        {
          label: 'Pipelines',
          routerLink: '/ticketpipelines/list',
        },
      ],
    };
    let obj = this.menuItems.find(o => o.label === 'Support');
    if (!obj) {
      // this.menuItems.splice(5, 0, supportLink);
      // localStorage.setItem('supportItem', 'true');
    }
  }

  loadUserDetails() {
    this.isLoading = true;
    this.userService.getUserDetails(this.userDetails.id).subscribe(
      (res) => {
        this.userInfo = res;
        this.isLoading = false;
      },
      (err) => {
        this.alert.error('Error occured: ' + err);
      }
    );
  }

  notificationIconClick() {
    localStorage.setItem('notificationClickTime', new Date().toString());
    this.getUserNotifications(this.userDetails.id, 1, 5);
    this.showNotificationBubble = false;
  }

  ngOnDestroy(): void {
    clearInterval(this.notificationInterval);
  }

  markAsRead(row) {
    this.updateUserNotifications(row.id, this.userId);

  }

  usersNav() {
    this.router.navigate(['/usernotification/list']);
  }
}
