export const environment = {
  production: true,
  serverUrl: 'http://localhost:5000',
  apiBase: '/api',
  //twitterOauthUrl: 'http://localhost:5000/api/twitter/auth/twitter',
  twitterOauthUrl: 'https://risepath.com/api/twitter/auth/twitter',
  linkedinOauthUl: 'https://risepath.com/api/linkedin/auth/linkedin',
  facebookOauthUrl: 'https://risepath.com/api/facebook/auth/facebook',
  instagramOauthUl: 'https://risepath.com/api/instagram/auth/instagram',
  risepathfacebookOauthUrl: 'https://risepath.com/api/facebook/auth/facebookchannel',
  risepathgoogleOauthUrl: 'https://risepath.com/api/google/auth/google',
  gmailOauthUrl: 'https://risepath.com/api/gmailconnect/auth/',
  meetingOauthUrl: 'https://risepath.com/api/googlecalendar/auth/',
  pinterestOauthUrl: 'https://risepath.com/api/pinterest/auth/pinterest',
  tiktokOauthUrl: 'https://risepath.com/api/tiktok/auth/tiktok',
  teamsOauthUrl: 'https://risepath.com/api/teams/auth/teams',
  slackOauthUrl: 'https://risepath.com/api/slack/auth/slack',
  outlookOauthUrl: 'https://risepath.com/api/outlook/auth/',
  youtubeOauthUrl: 'https://risepath.com/api/youtubeconnect/auth/',

  env: 'prod',
  widgetScriptTemplate: '<script data-custom-widget app-id="{{appId}}" src="https://risepath.com/api/widget/3rd/platform.js" async></script>',
  formsScriptTemplate: '<script data-custom-widget app-id="{{appId}}" src="https://risepath.com/api/widget/3rd/platform.js" async></script>'
};
