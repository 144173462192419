<!-- WRAP LAYOUT IF BOXED -->
<div class="container" *ngIf="_boxed; else basicLayoutBlock">
  <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
</div>
<ng-template #basicLayoutBlock>
  <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
</ng-template>

<!-- YOUR LAYOUT CONTENT GOES INSIDE HERE -->
<ng-template #contentTpl>
  <!-- PAGE SIDEBAR -->
  <pg-sidebar>
    <ng-template #sideBarOverlay>
      <div class="row">
        <div class="col-xs-6 no-padding">
          <a href="javascript:void(0)" class="p-l-40"><img src="assets/img/demo/social_app.svg" alt="socail">
          </a>
        </div>
        <div class="col-xs-6 no-padding">
          <a href="javascript:void(0)" class="p-l-10"><img src="assets/img/demo/email_app.svg" alt="socail">
          </a>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-6 m-t-20 no-padding">
          <a href="javascript:void(0)" class="p-l-40"><img src="assets/img/demo/calendar_app.svg" alt="socail">
          </a>
        </div>
        <div class="col-xs-6 m-t-20 no-padding">
          <a href="javascript:void(0)" class="p-l-10"><img src="assets/img/demo/add_more.svg" alt="socail">
          </a>
        </div>
      </div>
    </ng-template>
    <ng-template #sideBarHeader>
      <img src="assets/home/assets/images/logowhite.png" alt="logo" pgRetina
        src1x="assets/home/assets/images/logowhite.png" src2x="assets/home/assets/images/logowhite.png" width="118"
        height="22">

    </ng-template>
    <ng-template #menuItems>
      <pg-menu-items [Items]="menuLinks">
      </pg-menu-items>
    </ng-template>
  </pg-sidebar>
  <!-- PAGE CONTAINER -->
  <page-container>
    <pg-header [boxed]="_boxed">
      <!-- START MOBILE SIDEBAR TOGGLE -->
      <a href="javascript:void(0);" class="btn-icon-link toggle-sidebar d-lg-none" (click)="toggleMobileSidebar()">
        <i class="pg-icon">menu</i>
      </a>
      <!-- END MOBILE SIDEBAR TOGGLE -->
      <div class="">
        <!-- START EMAIL MOBILE TOGGLE -->
        <a href="javascript:void(0);" class="toggle-secondary-sidebar align-items-center"
          (click)="toggleSecondarySideBar()" *ngIf="_layoutOption === 'email'">
          <span class="d-flex align-items-center">
            Inbox <span class="text-info">(12)</span> <span class="pg-icon">drop_down</span>
          </span>
        </a>
        <!-- END EMAIL MOBILE TOGGLE -->

        <div class="brand inline p-l-50 m-l-20" [class.d-none]="_layoutOption === 'email'"
          style="cursor: pointer;outline: 0;" [routerLink]="['/dashboard/dashboardDetails']">
          <img src="assets/home/assets/images/logo-dark.png" alt="logo" pgRetina
            src1x="assets/home/assets/images/logo-dark.png" src2x="assets/home/assets/images/logo-dark.png" width="118"
            height="22">
        </div>
        <!-- START NOTIFICATION LIST -->
        <ul class="d-lg-inline-block d-none notification-list no-margin  b-grey b-l b-r no-style p-l-20 p-r-20">
          <li class="p-r-10 inline">
            <div class="dropdown" dropdown>
              <a href="javascript:;" id="notification-center" class="header-icon btn-icon-link" dropdownToggle>
                <i class="pg-icon">world</i>
                <span class="bubble"></span>
              </a>

              <div *dropdownMenu class="dropdown-menu notification-toggle">
                <div class="notification-panel">

                  <div class="notification-body scrollable" *ngFor="let report of activityReportsList.finalList">


                    <div class="notification-item  clearfix">
                      <div class="heading">
                        <a href="javascript:void(0)" class="text-black pull-left">
                          <i class="pg-icon m-r-10" *ngIf="(report.type.includes('NOTE'))">comment</i>
                          <i class="pg-icon m-r-10" *ngIf="(report.type.includes('TASK'))">note</i>
                          <i class="pg-icon m-r-10" *ngIf="(report.type.includes('EVENT'))">flag</i>
                          <i class="pg-icon m-r-10" *ngIf="(report.type.includes('FILE'))">folder</i>
                          <span class="bold">{{report.name}}</span>
                          <span class="fs-12 m-l-10"
                            *ngIf="report.createdUserDetails">{{report.createdUserDetails.firstName}}
                            {{report.createdUserDetails.lastName}}</span>
                          <span class="fs-12 m-l-10" *ngIf="!report.createdUserDetails">Rise, Path</span>
                        </a>
                        <span class="pull-right time">{{report.updatedAt | date:'MMM d'}}</span>
                      </div>
                      <!-- START Notification Item Right Side-->
                      <div class="option">
                        <a href="javascript:void(0)" class="mark"></a>
                      </div>
                      <!-- END Notification Item Right Side-->
                    </div>
                    <br />
                  </div>

                  <div class="notification-footer text-center">
                    <a [routerLink]="['/activityreports/list']">Read all notifications</a>
                    <a data-toggle="refresh" class="portlet-refresh text-black pull-right" href="javascript:void(0)">
                      <i class="pg-refresh_new"></i>
                    </a>
                  </div>

                </div>

              </div>

            </div>
          </li>

          <ng-template [ngIf]="_layoutOption != 'email'">
            <li class="p-r-10 inline">
              <a href="javascript:void(0)" class="header-icon btn-icon-link">
                <i class="pg-icon">link_alt</i>
              </a>
            </li>
            <li class="p-r-10 inline">
              <a [routerLink]="['/workspace/details']" class="header-icon btn-icon-link">
                <i class="pg-icon">grid_alt</i>
              </a>
            </li>
            <li class="p-r-10 inline">
              <a [routerLink]="['/domain/details']" class="header-icon btn-icon-link">
                <i class="pg-icon">grid_alt</i>
              </a>
            </li>
          </ng-template>

          <ng-template [ngIf]="_layoutOption == 'email'">
            <li class="inline">
              <a href="javascript:void(0)" id="mark-email" class="mark-email btn-icon-link m-l-10"><i
                  class="pg-icon">edit</i></a>
            </li>
          </ng-template>
        </ul>


        <form class="form-inline my-2 my-lg-0 d-lg-inline-block d-none">

          <input type="text" placeholder="Search" aria-label="Search" placeholder="type anything to search"
            class="form-control ml-2 mr-sm-2" [(ngModel)]='searchValue' name="searchBox" size="30">
          <button class="btn btn-lg btn-outline-complete my-2 my-sm-0" type="submit"
            (click)=searchKey(searchValue)>Search</button>
        </form>

      </div>
      <div class="d-flex align-items-center">

        <span class="mx-2"><b>{{userDetails.firstName}} {{userDetails.lastName}}</b></span>
        <div class="dropdown pull-right d-lg-block d-none" dropdown>

          <button class="profile-dropdown-toggle" type="button" dropdownToggle id="profileDropdown"
            aria-label="profile dropdown">
            <span class="thumbnail-wrapper d32 circular inline">
              <!-- <img src="{{userProfilePicturePath}}" alt="" onerror="this.src='assets/home/assets/images/userpic.jpg'"
                width="32" height="32"> -->
            </span>
          </button>
          <div class="dropdown-menu dropdown-menu-right profile-dropdown" *dropdownMenu role="menu">

            <a [routerLink]="['/users/details']" [queryParams]="{userId: userId}">Profile</a>
            <a (click)="goToLogout()" class="dropdown-item">Logout</a>

          </div>
        </div>

        <a href="javascript:void(0)" class="header-icon btn-icon-link m-l-5 sm-no-margin d-inline-block btn-icon-link"
          (click)="openQuickView($event)">
          <i class="pg-icon">menu_add</i>
        </a>
      </div>
    </pg-header>
    <div class="page-content-wrapper {{_pageContainerClass}}">

      <div class="content {{_contentClass}}">

        <router-outlet></router-outlet>
      </div>

      <ng-template [ngIf]="_footer">
        <div class=" container-fluid container-fixed-lg footer">
          <div class="copyright sm-text-center">
            <p class="small no-margin pull-left sm-pull-reset">
              <span class="hint-text">Copyright &copy; 2020</span>&nbsp;<span class="font-montserrat">Rise
                Path</span>.&nbsp;<span class="hint-text">All rights
                reserved.</span> <span class="sm-block"><a class="m-l-10 m-r-10"
                  [routerLink]="['/termsofuse/details']">Terms of
                  use</a> <span class="muted">|</span> <a class="m-l-10"
                  [routerLink]="['/privacypolicy/details']">Privacy
                  Policy</a></span>
            </p>



            <div class="clearfix">
            </div>
          </div>
        </div>

      </ng-template>
    </div>
  </page-container>

  <app-quickview></app-quickview>
</ng-template>