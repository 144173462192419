import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, filter, switchMap } from 'rxjs/operators';
import { AppConstants } from '../utilities/AppConstants';
import { CookieService } from 'ngx-cookie-service';

@Injectable({ providedIn: 'root' })
export class TenantDetailService {

    constructor(public http: HttpClient, public cookieService: CookieService) { }

    getAllTenantDetails(page, limit): Observable<any> {

        const getAllTenantDetailsUrl = AppConstants.GETALL_TENANTDETAILS + '?page=' + page + '&limit=' + limit;

        return this.http
            .get(getAllTenantDetailsUrl, {})
            .pipe(map(
                res => {
                    return res;
                },
                err => {
                    return err;
                }
            ));
    }

    getTenantDetailDetails(tenantDetailId): Observable<any> {

        const URL = AppConstants.GET_TENANTDETAIL + '/' + tenantDetailId;
        return this.http
            .get(URL, {})
            .pipe(map(
                res => {
                    return res;
                },
                err => {
                    return err;
                }
            ));
    }

    createTenantDetail(data): Observable<any> {

        const URL = AppConstants.CREATE_TENANTDETAIL + '';
        return this.http
            .post(URL, data)
            .pipe(map(
                res => {
                    return res;
                },
                err => {
                    return err;
                }
            ));
    }

    updateTenantDetail(data): Observable<any> {

        const URL = AppConstants.UPDATE_TENANTDETAIL + '';
        return this.http
            .post(URL, data)
            .pipe(map(
                res => {
                    return res;
                },
                err => {
                    return err;
                }
            ));
    }

    deleteTenantDetail(form): Observable<any> {

        const URL = AppConstants.DELETE_TENANTDETAIL + '';
        return this.http
            .delete(URL, {})
            .pipe(map(
                res => {
                    return res;
                },
                err => {
                    return err;
                }
            ));
    }

}