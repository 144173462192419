import { NgZone, enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
// import 'hammerjs';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import 'zone.js/dist/zone';

if (environment.production) {
  enableProdMode();
}

// import 'zone.js/dist/zone'  // Included with Angular CLI.

// platformBrowserDynamic()
//     .bootstrapModule(AppModule, { ngZone: new NgZone({}) })

platformBrowserDynamic().bootstrapModule(AppModule);