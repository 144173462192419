<div id="quickview" class="quickview-wrapper" [class.open]=isOpen>
<!-- Nav tabs -->
<tabset>
	<tab heading="Notes" id="quickview-notes" customClass="full-height">
	  <div class="view-port clearfix quickview-notes" [class.push]="isNoteOpen" id="note-views">
	    <!-- BEGIN Note List !-->
	    <div class="view" id="quick-note-list">
				<div class="full-height d-flex flex-column list">
					<div class="toolbar clearfix">
						<ul class="pull-right ">
							<!-- <li>
								<a class="delete-note-link" (click)="deleteMode()" [class.selected]="deleteNoteMode"><i class="pg-icon">trash_alt</i></a>
							</li> -->
							<li>
								<a class="new-note-link" (click)="composeNote()" [class.hide]="deleteNoteMode"><i class="pg-icon">add</i></a>
							</li>
						</ul>
						<button class="btn-remove-notes btn btn-xs btn-block" [class.hide]="!deleteNoteMode" (click)="deleteNote()"><i class="pg-icon">close</i> Delete</button>
					</div>
					<ul perfectScrollbar class="full-height">
						<!-- BEGIN Note Item !-->
						<li *ngFor="let note of noteList" (click)="onSelectNote(note)" class="d-flex justify-space-between">
							<div class="left">
								<!-- BEGIN Note Action !-->
								<div class="form-check warning no-margin" [ngStyle]="deleteNoteMode && {'display': 'block'}">
									<input id="qncheckbox{{note.id}}" type="checkbox" value="1" (change)="onCheck($event,note)">
									<label for="qncheckbox{{note.id}}"></label>
								</div>
								<!-- END Note Action !-->
								<!-- BEGIN Note Preview Text !-->
								<p class="note-preview" [innerHTML]="note.name"></p>
								<!-- BEGIN Note Preview Text !-->
							</div>
							<!-- BEGIN Note Details !-->
							<div class="d-flex right justify-content-end">
								<!-- BEGIN Note Date !-->
								<span class="date">{{note.createdAt | date:'dd/MM/yy'}}</span>
								<a class="d-flex align-items-center"><i class="pg-icon">chevron_right</i></a>
								<!-- END Note Date !-->
							</div>
							<!-- END Note Details !-->
						</li>
						<!-- END Note List !-->
					</ul>
				</div>
	    </div>
	    <!-- END Note List !-->
	    <div class="view note" id="quick-note">
			
					<!-- <div class="extra-component" *ngIf="selectedNote">
						<button (click)="toggleNotesView()" class="btn btn-sm close-note-link"><i class="pg-icon">chevron_left</i></button>
							<div class="top">
								<span>{{selectedNote.name}}</span>
								<br>
								<span>{{selectedNote.createdAt | date:'dd LLLL yyyy'}}</span>
							</div>
					</div> -->
					<!-- <app-alert></app-alert> -->
					<!-- <alert></alert> -->
					<quill-editor class="full-height full-width" placeholder="" [(ngModel)]="noteText" [modules]="editorModules">
							<ul quill-editor-toolbar class="ql-toolbar ql-snow">
									<li>
										<button (click)="toggleNotesView()" class="close-note-link"><i class="pg-icon">chevron_left</i></button>
									</li>
								<li class="ql-formats">
										<button class="ql-bold" [title]="'Bold'"></button>
								</li>
								<li class="ql-formats">
										<button class="ql-italic" [title]="'Italic'"></button>
								</li>
								<li class="ql-formats">
										<button (click)="saveNote()">Save</button>
								</li>
							</ul>
							
						</quill-editor>
	    </div>
	  </div>

	</tab>
	<tab heading="Alerts" id="quickview-alerts" customClass="full-height">
	  <div class="view-port clearfix" id="alerts">
	    <!-- BEGIN Alerts View !-->
	    <div class="view bg-white">
				<div class="full-height d-flex flex-column">
					<!-- BEGIN View Header !-->
					<!-- <div class="navbar navbar-default navbar-sm">
						<div class="navbar-inner"> -->
							<!-- BEGIN Header Controler !-->
							<!-- <a class="action p-l-10 link text-color" data-navigate="view" data-view-port="#chat" data-view-animation="push-parrallax">
								<i class="pg-icon">more_horizontal</i>
							</a> -->
							<!-- END Header Controler !-->
							<!-- <div class="view-heading">
								Notications
							</div> -->
							<!-- BEGIN Header Controler !-->
							<!-- <a class="action p-r-10 pull-right link text-color">
								<i class="pg-icon">search</i>
							</a> -->
							<!-- END Header Controler !-->
						<!-- </div>
					</div> -->
					<!-- END View Header !-->
					<!-- BEGIN Alert List !-->
					<div perfectScrollbar class="full-height list-view boreded no-top-border">
						<!-- BEGIN List Group !-->
						<div class="list-view-group-container">
							<div class="list-view-group-header text-uppercase">
								NOTES
							</div>
							<ul>
								<li class="alert-list" *ngFor="let report of noteAlertList">
									<a class="align-items-center" data-navigate="view" data-view-port="#chat" data-view-animation="push-parrallax">
										<p class="">
											<span class="text-warning fs-10"><i class="pg-icon">circle_fill</i></span>
										</p>
										<p class="p-l-10 overflow-ellipsis fs-12">
											<span class="text-color">{{report.name}}</span>
										</p>
										<p class="p-r-10 ml-auto fs-12 text-right">
											<span class="pull-right time">{{report.updatedAt | date:'MMM d'}}</span>
										</p>
									</a>
	
								</li>
					
							</ul>
						</div>
						<div class="list-view-group-container">
							<div class="list-view-group-header text-uppercase">
								TASKS
							</div>
							<ul>
								<li class="alert-list" *ngFor="let report of taskAlertList">
									<a class="align-items-center" data-navigate="view" data-view-port="#chat" data-view-animation="push-parrallax">
										<p class="">
											<span class="text-warning fs-10"><i class="pg-icon">circle_fill</i></span>
										</p>
										<p class="p-l-10 overflow-ellipsis fs-12">
											<span class="text-color">{{report.name}}</span>
										</p>
										<p class="p-r-10 ml-auto fs-12 text-right">
											<span class="pull-right time">{{report.updatedAt | date:'MMM d'}}</span>
										</p>
									</a>
	
								</li>
					
							</ul>
						</div>
			
						<div class="list-view-group-container">
							<div class="list-view-group-header text-uppercase">
								EVENTS
							</div>
							<ul>
								<li class="alert-list" *ngFor="let report of eventAlertList">
									<a class="align-items-center" data-navigate="view" data-view-port="#chat" data-view-animation="push-parrallax">
										<p class="">
											<span class="text-warning fs-10"><i class="pg-icon">circle_fill</i></span>
										</p>
										<p class="p-l-10 overflow-ellipsis fs-12">
											<span class="text-color">{{report.name}}</span>
										</p>
										<p class="p-r-10 ml-auto fs-12 text-right">
											<span class="pull-right time">{{report.updatedAt | date:'MMM d'}}</span>
										</p>
									</a>
	
								</li>
					
							</ul>
						</div>

						<div class="list-view-group-container">
							<div class="list-view-group-header text-uppercase">
								FILES
							</div>
							<ul>
								<li class="alert-list" *ngFor="let report of fileAlertList">
									<a class="align-items-center" data-navigate="view" data-view-port="#chat" data-view-animation="push-parrallax">
										<p class="">
											<span class="text-warning fs-10"><i class="pg-icon">circle_fill</i></span>
										</p>
										<p class="p-l-10 overflow-ellipsis fs-12">
											<span class="text-color">{{report.name}}</span>
										</p>
										<p class="p-r-10 ml-auto fs-12 text-right">
											<span class="pull-right time">{{report.updatedAt | date:'MMM d'}}</span>
										</p>
									</a>
	
								</li>
					
							</ul>
						</div>
					</div>
					<!-- END Alert List !-->
				</div>
	    </div>
	    <!-- EEND Alerts View !-->
	  </div>
	</tab>
	<tab heading="Users" id="quickview-chat" active="true" customClass="full-height">
	  <div class="view-port clearfix" id="users">
	    <div class="bg-white">
	      <!-- BEGIN View Header !-->
	      <div class="navbar navbar-default">
	        <div class="navbar-inner">
	          <!-- BEGIN Header Controler !-->
	          <a class="action p-l-10 link text-color"  pg-view-trigger parentView="chat" animationType="push-parrallax" (click)="showSlideUp()">
	            <i class="pg-icon">add</i>
	          </a>
	          <!-- END Header Controler !-->
	          <div class="view-heading">
	            Users List
	            <!-- <div class="fs-11">Show All</div> -->
	          </div>
	          <!-- BEGIN Header Controler !-->
	          <a class="action p-r-10 pull-right link text-color">
	            <i class="pg-icon">more_horizontal</i>
	          </a>
	          <!-- END Header Controler !-->
	        </div>
	      </div>
	      <!-- END View Header !-->
	      <pg-list-view-container class="scrollable full-height">
						<pg-list-item *ngFor="let group of userList">
								<ng-template #ItemHeading>
										{{group.group}}
								</ng-template>
							<li class="chat-user-list clearfix"  *ngFor="let user of group.users">
								<a pg-view-trigger parentView="chat" animationType="push-parrallax">
										<span class="thumbnail-wrapper d32 circular bg-success">
												<!-- <img width="25" height="25" alt="" src="{{group.profilePictureDetails}}"  onerror="this.src='assets/home/assets/images/userpic.jpg'" class="col-top"> -->
										</span>
										
										<p class="p-l-10 ">
											<span class="text-color" (click)="goToUserPage(user)">{{user.username}}</span>
											<span class="block text-color hint-text fs-12">{{user.lastMessage}}</span>
										</p>
									</a>
								</li>
						</pg-list-item>
				</pg-list-view-container>
	    </div>
	    

	  </div>
	</tab>
</tabset>
<a class="btn-icon-link invert quickview-toggle" (click)="toggle()"><i class="pg-icon">close</i></a>
</div>

<div bsModal #mdSlideUp="bs-modal" class="modal fade slide-up disable-scroll" tabindex="-1" role="dialog"
  aria-hidden="false">
  <div class="modal-dialog modal-{{slideUp.type}}">
    <div class="modal-content-wrapper">
      <div class="modal-content">
        <div class="modal-header clearfix text-left">
          <button type="button" class="close" (click)="mdSlideUp.hide()" aria-hidden="true"><i class="pg-icon">close</i>
          </button>
          <h5>Create <span class="semi-bold">User</span></h5>
        </div>
        <div class="modal-body">
			<form class="p-t-15" role="form" [formGroup]="userForm" (ngSubmit)="_submitForm()">
				<div class="row">
				  <div class="col-md-6">
					<div pgFormGroupDefault class="form-group form-group-default required"
					  [class.has-error]="
					   userForm.get('txtfname').invalid &&
						(userForm.get('txtfname').dirty ||
						  userForm.get('txtfname').touched)
					  ">
					  <label>First Name</label>
					  <input type="text" placeholder="First Name" class="form-control" id="txtfname" name="txtfname"
					   formControlName="txtfname" required>
					</div>
					<div *ngIf="
					  userForm.get('txtfname').invalid &&
					  (userForm.get('txtfname').dirty ||
						userForm.get('txtfname').touched)
					">
					  <label *ngIf="
					  userForm.get('txtfname').errors
					  .required ||
					 userForm.get('txtfname').errors
					  .whitespace
					  " class="error">This field is required.</label>
					</div>
				  </div>
				  <div class="col-md-6">
					<div pgFormGroupDefault class="form-group form-group-default required"
					  [class.has-error]="
					   userForm.get('txtlname').invalid &&
					  (userForm.get('txtlname').dirty ||
						userForm.get('txtlname').touched)
					  ">
					  <label>Last Name</label>
					  <input type="text" placeholder="Last Name" class="form-control" id="txtlname" name="txtlname"
						formControlName="txtlname" required>
					</div>
					<div *ngIf="
					 userForm.get('txtlname').invalid &&
					  (userForm.get('txtlname').dirty ||
						userForm.get('txtlname').touched)
					">
					  <label *ngIf="
					  userForm.get('txtlname').errors
					  .required ||
					 userForm.get('txtlname').errors
					  .whitespace" class="error">This field is required.</label>
					</div>
				  </div>
				</div>
				<div class="row">
				  <div class="col-md-12">
					<div pgFormGroupDefault class="form-group form-group-default required"
					  [class.has-error]="
					   userForm.get('txtemail').invalid &&
					  (userForm.get('txtemail').dirty ||
						userForm.get('txtemail').touched)
					  ">
					  <label>Email</label>
					  <input type="email" placeholder="Enter Email here" class="form-control"
						id="txtemail" name="txtemail"  formControlName ="txtemail"  required>
					</div>
					<div *ngIf="
					 userForm.get('txtemail').invalid &&
					  (userForm.get('txtemail').dirty ||
						userForm.get('txtemail').touched)
					">
					  <label *ngIf="
					   userForm.get('txtemail').errors
						.required ||
					  userForm.get('txtemail').errors
						.whitespace
					  " class="error">This field is required.</label>
					</div>
				  </div>
				</div>
				<div class="row">
				  <div class="col-md-12">
					<div pgFormGroupDefault class="form-group form-group-default">
					  <label>Phone Number</label>
					  <input type="text" placeholder="Your Phone Number" class="form-control" id="txtphoneNumber"
						name="txtphoneNumber" formControlName="txtphoneNumber"  maxlength="13" >
					</div>
					<div *ngIf="
					userForm.get('txtphoneNumber').invalid &&
					(userForm.get('txtphoneNumber').dirty || userForm.get('txtphoneNumber').touched)
				  ">
					<label *ngIf="userForm.get('txtphoneNumber').errors.pattern" class="error">Enter Valid Phone
					  Number.</label>
				  </div>          
				  </div>
				</div>
			
				<div class="row m-t-25">
				  <div class="col-xl-6 p-b-10">
					<p class="small-text hint-text">Create User to access the account</p>
				  </div>
				  <div class="col-xl-6">
					<button class="btn btn-complete pull-right btn-lg btn-block" type="submit"
					  [disabled]="userForm.invalid">Submit</button>
				  </div>
				</div>
			  </form>
        </div>
      </div>
    </div>
    <!-- /.modal-content -->
  </div>
</div>