import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, filter, switchMap } from 'rxjs/operators';
import { AppConstants } from '../utilities/AppConstants';
import { CookieService } from 'ngx-cookie-service';


@Injectable({ providedIn: 'root' })
export class UserNotificationService {

    constructor(public http: HttpClient, public cookieService: CookieService) { }

    getAllUserNotifications(userId, page, limit): Observable<any> {

        const getAllUserNotificationsUrl = AppConstants.GETALL_USERNOTIFICATIONS + `/${userId}/notifications` + '?page=' + page + '&limit=' + limit;
        return this.http
            .get(getAllUserNotificationsUrl, {})
            .pipe(map(
                res => {
                    return res;
                },
                err => {
                    return err;
                }
            ));
    }
    getAllUserNotificationswithDates(userId, startDate, endDate): Observable<any> {

        let query = ""
        if (startDate && endDate) {
            query += `?startDate=${startDate}&endDate=${endDate}`
        }

        const getAllUserNotificationsUrl = AppConstants.GETALL_USERNOTIFICATIONS + `/${userId}/notifications/` + query;

        return this.http
            .get(getAllUserNotificationsUrl, {})
            .pipe(map(
                res => {
                    return res;
                },
                err => {
                    return err;
                }
            ));
    }


    getUserNotificationDetails(userNotificationId): Observable<any> {

        const URL = AppConstants.GET_USERNOTIFICATION + '/' + userNotificationId;
        return this.http
            .get(URL, {})
            .pipe(map(
                res => {
                    return res;
                },
                err => {
                    return err;
                }
            ));
    }

    createUserNotification(data): Observable<any> {

        const URL = AppConstants.CREATE_USERNOTIFICATION + `/${data.userId}/notifications`;
        return this.http
            .post(URL, data)
            .pipe(map(
                res => {
                    return res;
                },
                err => {
                    return err;
                }
            ));
    }

    updateUserNotification(data): Observable<any> {

        const URL = AppConstants.GETALL_USERNOTIFICATIONS + `/${data.userId}/notifications/` + data.userNotificationId;
        return this.http
            .put(URL, data)
            .pipe(map(
                res => {
                    return res;
                },
                err => {
                    return err;
                }
            ));
    }

    deleteUserNotification(userNotificationId): Observable<any> {

        const URL = AppConstants.DELETE_USERNOTIFICATION + '/' + userNotificationId;
        return this.http
            .delete(URL, {})
            .pipe(map(
                res => {
                    return res;
                },
                err => {
                    return err;
                }
            ));
    }
}