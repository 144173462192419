import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, filter, switchMap } from 'rxjs/operators';
import { AppConstants } from '../utilities/AppConstants';
import { CookieService } from 'ngx-cookie-service';

@Injectable({ providedIn: 'root' })
export class UserNoteService {

    constructor(public http: HttpClient, public cookieService: CookieService) { }

    getAllUserNotes(page, limit, userId): Observable<any> {
        
        const getAllUserNotesUrl = AppConstants.GETALL_USERNOTES  +'/'+ userId +'/notes'+'?page='+ page +'&limit=' +limit;
        return this.http
            .get(getAllUserNotesUrl, {})
            .pipe(map(
                res => {
                    return res;
                },
                err => {
                    return err;
                }
            ));
    }

    getUserNoteDetails(userNoteId): Observable<any> {
        
        const URL = AppConstants.GET_USERNOTE + '/' + userNoteId;
        return this.http
            .get(URL, {})
            .pipe(map(
                res => {
                    return res;
                },
                err => {
                    return err;
                }
            ));
    }

    createUserNote(data): Observable<any> {
        
        const URL = AppConstants.CREATE_USERNOTE + '';
        return this.http
            .post(URL, data)
            .pipe(map(
                res => {
                    return res;
                },
                err => {
                    return err;
                }
            ));
    }

    updateUserNote(data): Observable<any> {
        
        const URL = AppConstants.UPDATE_USERNOTE + '/'+ data.userId+'/notes/'+data.userNoteId;
        return this.http
            .put(URL, data)
            .pipe(map(
                res => {
                    return res;
                },
                err => {
                    return err;
                }
            ));
    }

    deleteUserNote(form): Observable<any> {
        
        const URL = AppConstants.DELETE_USERNOTE +'';
        return this.http
            .delete(URL, {})
            .pipe(map(
                res => {
                    return res;
                },
                err => {
                    return err;
                }
            ));
    }

}