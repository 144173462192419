import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, filter, switchMap } from 'rxjs/operators';
import { AppConstants } from '../utilities/AppConstants';
import { CookieService } from 'ngx-cookie-service';


@Injectable({ providedIn: 'root' })
export class ActivityReportsService {

    constructor(public http: HttpClient, public cookieService: CookieService) { }

    getAllActivityReports(page, limit): Observable<any> {

        const getAllActivityReportssUrl = AppConstants.GETALL_ACTIVITYREPORTS + '?page=' + page + '&limit=' + limit;
        return this.http
            .get(getAllActivityReportssUrl, {})
            .pipe(map(
                res => {
                    return res;
                },
                err => {
                    return err;
                }
            ));
    }

    getMyActivityReports(page, limit, userId): Observable<any> {

        const getAllActivityReportssUrl = AppConstants.GETMY_ACTIVITYREPORTS + '/myreports?page=' + page + '&limit=' + limit + '&userId=' + userId;
        return this.http
            .get(getAllActivityReportssUrl, {})
            .pipe(map(
                res => {
                    return res;
                },
                err => {
                    return err;
                }
            ));
    }

    getActivityReportsDetails(activityReportsId): Observable<any> {

        const URL = AppConstants.GET_ACTIVITYREPORT + '/' + activityReportsId;
        return this.http
            .get(URL, {})
            .pipe(map(
                res => {
                    return res;
                },
                err => {
                    return err;
                }
            ));
    }

    createActivityReports(data): Observable<any> {

        const URL = AppConstants.CREATE_ACTIVITYREPORT + '';
        return this.http
            .post(URL, data)
            .pipe(map(
                res => {
                    return res;
                },
                err => {
                    return err;
                }
            ));
    }

    updateActivityReports(data): Observable<any> {

        const URL = AppConstants.UPDATE_ACTIVITYREPORT + '/' + data.id;
        return this.http
            .put(URL, data)
            .pipe(map(
                res => {
                    return res;
                },
                err => {
                    return err;
                }
            ));
    }

    deleteActivityReports(activityReportsId): Observable<any> {

        const URL = AppConstants.DELETE_ACTIVITYREPORT + '/' + activityReportsId;
        return this.http
            .delete(URL, {})
            .pipe(map(
                res => {
                    return res;
                },
                err => {
                    return err;
                }
            ));
    }

    getAllActivities(page, limit, userId): Observable<any> {

        const getAllActivityUrl = AppConstants.GETALL_ACTIVITIES + '?page=' + page + '&limit=' + limit + '&userId=' + userId;
        return this.http
            .get(getAllActivityUrl, {})
            .pipe(map(
                res => {
                    return res;
                },
                err => {
                    return err;
                }
            ));
    }

    getAllActivitiesQuery(page, limit, type, viewType, search): Observable<any> {
        let getAllActivityUrl;
        if (type) {
            getAllActivityUrl = AppConstants.GETALL_ACTIVITIES + '?page=' + page + '&limit=' + limit + '&type=' + type;

        } else {
            getAllActivityUrl = AppConstants.GETALL_ACTIVITIES + '?page=' + page + '&limit=' + limit;

        }
        if (viewType){
              getAllActivityUrl = AppConstants.GETALL_ACTIVITIES + '?page=' + page + '&limit=' + limit + '&type=' + type + '&viewType=' + viewType;

        }
        if(search){
            getAllActivityUrl += '&search='+ search;
        }
        return this.http
            .get(getAllActivityUrl, {})
            .pipe(map(
                res => {
                    return res;
                },
                err => {
                    return err;
                }
            ));
    }

    getAllActivitieswithDates(startDate, endDate): Observable<any> {

        let query = ""
        if (startDate && endDate) {
            query += `?startDate=${startDate}&endDate=${endDate}`
        }

        const getAllActivityUrl = AppConstants.GETALL_ACTIVITIES + '/' + query;

        return this.http
            .get(getAllActivityUrl, {})
            .pipe(map(
                res => {
                    return res;
                },
                err => {
                    return err;
                }
            ));
    }

    getOverAllActivitiesQuery(page, limit): Observable<any> {


        const getAllActivityUrl = AppConstants.GETALL_ACTIVITIES + '/AllActivities' + '?page=' + page + '&limit=' + limit;
        return this.http
            .get(getAllActivityUrl, {})
            .pipe(map(
                res => {
                    return res;
                },
                err => {
                    return err;
                }
            ));
    }

}