import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { pgUtilModule } from '../util/pg-util.module';
import { TimeAgoPipe } from './timeago.component';

@NgModule({
    imports: [CommonModule, OverlayModule, pgUtilModule],
    declarations: [TimeAgoPipe],
    exports: [TimeAgoPipe]
})
export class TimeAgoPipeModule { }