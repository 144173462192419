<div class="card-header" [attr.aria-expanded]="_active" (click)="clickHeader($event)" role="tab"
style="display: flex;justify-content: space-between;"
>
  <div>
    <ng-template [ngIf]="Title">
        <h4 class="card-title">
          <a href="javascript:;" [ngClass]="{'collapsed':!_active}">
          {{ Title }}
          </a>
        </h4>
    </ng-template>
    <ng-template [ngIf]="!Title">
      <ng-content select="[collapse-title]"></ng-content>
    </ng-template>
  </div>
  <ng-template [ngIf]="CheckboxTitle">
    <div style="margin-right: 22px;" class="form-check complete">
      <input type="checkbox">
      <label>
        <span class=" font-montserrat fs-11 all-caps"><b>{{ CheckboxTitle }}</b></span>
      </label>
    </div>
  </ng-template>
</div>
<div class="collapse" [ngClass]="{'show':true,'active':_active,'inactive':!_active}" [@collapseState]="_active?'active':'inactive'">
  <div class="card-body">
    <ng-content></ng-content>
  </div>
</div>