import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { LoginService } from '../services/login.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private loginService: LoginService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        const currentUser = this.loginService.getCurrentUser();
        let newHeaders = request.headers;
        if (currentUser && currentUser.token) {
            newHeaders = newHeaders.append('Authorization', `Bearer ${currentUser.token}`);
            // request = request.clone({
            //     setHeaders: {
            //         Authorization: `${currentUser.token}`
            //     }
            // });
        }
        // request = request.clone({
        //     setHeaders: {
        //         Authorization: `${currentUser.token}`
        //     }
        // });
        const authReq = request.clone({ headers: newHeaders });
        
        return next.handle(authReq);
    }
}