import { environment } from '../../../environments/environment';
export class AppConstants {

  public static readonly HOST_IP = environment.apiBase;

  //Twitter
  public static readonly GET_TWITTERAUTH: String = AppConstants.HOST_IP + "/twitter/auth/twitter";
  public static readonly SAVE_TWITTERAUTH: String = AppConstants.HOST_IP + "/support/twitter";
  public static readonly SAVE_MARKETINGTWITTERAUTH: String = AppConstants.HOST_IP + "/marketing/twitter";
  public static readonly ADD_TWITTERSUBSCRIPTION: String = AppConstants.HOST_IP + "/twitter/twitter-channel-subscribe";
  public static readonly ADD_MARKETINGTWITTERSUBSCRIPTION: String = AppConstants.HOST_IP + "/twitter/twitter-marketing-channel-subscribe";
  public static readonly GETALL_TWITTERACCOUNTS: String = AppConstants.HOST_IP + "/support/twitter";
  public static readonly DELETE_TWITTERACCOUNTS: String = AppConstants.HOST_IP + "/support/twitter";
  public static readonly GETALL_MARKETINGTWITTERACCOUNTS: String = AppConstants.HOST_IP + "/marketing/twitter";
  public static readonly UPDATE_MARKETINGTWITTERACCOUNTS: String = AppConstants.HOST_IP + "/marketing/twitter/update";
  public static readonly GETALL_MARKETINGFACEBOOKACCOUNTS: String = AppConstants.HOST_IP + "/facebook";
  public static readonly GET_FACEBOOK_POST_STAT: String = AppConstants.HOST_IP + "/facebook";
  public static readonly GET_MARKETINGFACEBOOKCURRENTUSER: String = AppConstants.HOST_IP + "/facebook";
  //public static readonly GET_TWITTERAUTH: String = "http://localhost:5000/auth/twitter";
  //Linkedin
  public static readonly GET_LINKEDINAUTH: String = AppConstants.HOST_IP + "/linkedin/auth/linkedin";
  public static readonly POST_LINKEDINCONTENT: String = AppConstants.HOST_IP + "/linkedin";
  public static readonly SAVE_LINKEDINAUTH: String = AppConstants.HOST_IP + "/support/linkedin";
  public static readonly SAVE_MARKETINGLINKEDINAUTH: String = AppConstants.HOST_IP + "/marketing/linkedin";
  public static readonly ADD_LINKEDINSUBSCRIPTION: String = AppConstants.HOST_IP + "/linkedin/linkedin-channel-subscribe";
  public static readonly ADD_MARKETINGLINKEDINSUBSCRIPTION: String = AppConstants.HOST_IP + "/linkedin/linkedin-marketing-channel-subscribe";
  public static readonly GETALL_LINKEDINACCOUNTS: String = AppConstants.HOST_IP + "/support/linkedin";
  public static readonly GETALL_MARKETINGLINKEDINACCOUNTS: String = AppConstants.HOST_IP + "/marketing/linkedin";
  public static readonly GETALL_LINKEDINPAGESLIST: String = AppConstants.HOST_IP + "/marketing/linkedin";
  public static readonly POST_TO_LINKEDIN: String = AppConstants.HOST_IP + '/';
  // INSTAGRAM
  public static readonly INSTAGRAM_MARKETING: String = AppConstants.HOST_IP + '/marketing/instagram';
  public static readonly INSTAGRAM_MARKETING_GET: String = AppConstants.HOST_IP + '/marketing/instagram';
  public static readonly POST_INSTAGRAMCONTENT: String = AppConstants.HOST_IP + '/instagram';
  public static readonly GETALL_MARKETINGINSTAGRAMACCOUNTS: String = AppConstants.HOST_IP + "/instagram";
  public static readonly DELETE_INSTAGRAM_ACCOUNT: String = AppConstants.HOST_IP + '/marketing/instagram';
  //Auth
  public static readonly REGISTER_URL: String = AppConstants.HOST_IP + "/users/account/register";
  public static readonly LOGIN_URL: String = AppConstants.HOST_IP + "/users/account/login";
  public static readonly FORGOT_PASSWORD_URL: String = AppConstants.HOST_IP + "/users/account/recover";
  public static readonly RESET_TOKEN_URL: String = AppConstants.HOST_IP + "/users/account/reset/confirm/";
  public static readonly CONFIRM_PASSWORD_URL: String = AppConstants.HOST_IP + "/users/account/reset/";
  public static readonly VALIDATE_USER_TOKEN_URL: String = AppConstants.HOST_IP + "/users";

  public static readonly VERIFYACCOUNT_URL: String = AppConstants.HOST_IP + "/support/account/recover/email/";
  public static readonly VALIDATE_TOKEN_URL: String = AppConstants.HOST_IP + "/support/account/reset/confirm/";
  public static readonly GET_EMAIL: String = AppConstants.HOST_IP + "/support/account"
  public static readonly SAVEACCOUNT_URL: String = AppConstants.HOST_IP + "/support/account";
  public static readonly UPDATE_EMAIL: String = AppConstants.HOST_IP + "/support/account"
  public static readonly CREATEEMAILACCOUNT_URL: String = AppConstants.HOST_IP + "/v1/email/create-inbox"
  //Users
  public static readonly GETALL_USERS: String = AppConstants.HOST_IP + '/users';
  public static readonly ALL_USERS: String = AppConstants.HOST_IP + '/users/getUsers?page=1&limit=100';
  public static readonly ALL_CHATLIST: String = AppConstants.HOST_IP + '/users/chatlist/getUsers?page=1&limit=100';
  public static readonly GET_USER: String = AppConstants.HOST_IP + '/users';
  public static readonly GET_LASTACCESSPIPELINE: String = AppConstants.HOST_IP + '/users/lastaccesspipeline';
  public static readonly CREATE_USER: String = AppConstants.HOST_IP + '/users';
  public static readonly SEND_VERIFICATION_MAIL: String = AppConstants.HOST_IP + '/users';
  public static readonly UPDATE_USER: String = AppConstants.HOST_IP + '/users';
  public static readonly UPDATE_USERLASTACCESSPIPE: String = AppConstants.HOST_IP + '/users/email';
  public static readonly DELETE_USER: String = AppConstants.HOST_IP + '/users';

  public static readonly CREATE_EMAIL_SETTINGS: String = AppConstants.HOST_IP + '/emailsettings';
  public static readonly GETALL_EMAIL_SETTINGS: String = AppConstants.HOST_IP + '/emailsettings';
  public static readonly GET_EMAIL_SETTINGS: String = AppConstants.HOST_IP + '/emailsettings';

  //Deals
  public static readonly GETALL_DEALS: String = AppConstants.HOST_IP + '/deals';
  public static readonly SHOWALL_DEALS: String = AppConstants.HOST_IP + '/deals/allDeals';
  public static readonly ALL_DEALS: String = AppConstants.HOST_IP + '/deals/getdeals/dealtitles?page=1&limit=100';
  public static readonly GET_DEAL: String = AppConstants.HOST_IP + '/deals';
  public static readonly CREATE_DEAL: String = AppConstants.HOST_IP + '/deals';
  public static readonly UPDATE_DEAL: String = AppConstants.HOST_IP + '/deals';
  public static readonly DELETE_DEAL: String = AppConstants.HOST_IP + '/deals';
  public static readonly GET_DEALDETAILS: String = AppConstants.HOST_IP + '/deals';


  //DealContacts
  public static readonly GETALL_DEALCONTACTS: String = AppConstants.HOST_IP + '/deals';
  public static readonly GET_DEALCONTACT: String = AppConstants.HOST_IP + '/deals';
  public static readonly CREATE_DEALCONTACT: String = AppConstants.HOST_IP + '/deals';
  public static readonly UPDATE_DEALCONTACT: String = AppConstants.HOST_IP + '/deals';
  public static readonly DELETE_DEALCONTACT: String = AppConstants.HOST_IP + '/deals';

  //Organizations
  public static readonly GETALL_ORGANIZATIONS: String = AppConstants.HOST_IP + '/organizations';
  public static readonly GET_ORGANIZATION: String = AppConstants.HOST_IP + '/organizations';
  public static readonly ORGANIZATIONSLIST: String = AppConstants.HOST_IP + '/organizations/getOrganizations?page=1&limit=100';
  public static readonly CREATE_ORGANIZATION: String = AppConstants.HOST_IP + '/organizations';
  public static readonly UPDATE_ORGANIZATION: String = AppConstants.HOST_IP + '/organizations';
  public static readonly DELETE_ORGANIZATION: String = AppConstants.HOST_IP + '/organizations';
  public static readonly EXPORT_ORGANIZATION: String = AppConstants.HOST_IP + '/organizations';
  public static readonly IMPORT_ORGANIZATION: String = AppConstants.HOST_IP + '/organizations';

  //Marketing Ads
  public static readonly CREATE_MARKETINGADS: String = AppConstants.HOST_IP + '/marketingAds';
  public static readonly GETALL_MARKETINGADS: String = AppConstants.HOST_IP + '/marketingAds';
  public static readonly MARKETINGADSLIST: String = AppConstants.HOST_IP + '/marketingAds/getMarketingAds?page=1&limit=100';
  public static readonly GET_MARKETINGADS: String = AppConstants.HOST_IP + '/marketingAds';
  public static readonly UPDATE_MARKETINGADS: String = AppConstants.HOST_IP + '/marketingAds';
  public static readonly DELETE_MARKETINGADS: String = AppConstants.HOST_IP + '/marketingAds';

  //OrganizationTimeline
  public static readonly GETALL_ORGANIZATIONTIMELINE: String = AppConstants.HOST_IP + '/organizations';
  public static readonly GET_ORGANIZATIONTIMELINE: String = AppConstants.HOST_IP + '/organizations';
  public static readonly CREATE_ORGANIZATIONTIMELINE: String = AppConstants.HOST_IP + '/organizations';
  public static readonly UPDATE_ORGANIZATIONTIMELINE: String = AppConstants.HOST_IP + '/organizations';
  public static readonly DELETE_ORGANIZATIONTIMELINE: String = AppConstants.HOST_IP + '/organizations';

  //Contacts
  public static readonly GETALL_WIDGETS: String = AppConstants.HOST_IP + '/widget';
  public static readonly GET_WIDGET: String = AppConstants.HOST_IP + '/widget';
  public static readonly CREATE_WIDGET: String = AppConstants.HOST_IP + '/widget';
  public static readonly WIDGET_CODE: String = AppConstants.HOST_IP + '/tenantwidget/widget-code';
  public static readonly GET_WIDGET_CODE: String = AppConstants.HOST_IP + '/tenantwidget/tenant-widget-code';
  public static readonly UPDATE_WIDGET: String = AppConstants.HOST_IP + '/widget';
  public static readonly DELETE_WIDGET: String = AppConstants.HOST_IP + '/widget';


  //tickets
  //''+
  public static readonly GET_TICKETGRAPHSTATS: String = AppConstants.HOST_IP + '/ticketGraphStats';
  public static readonly GETALL_TICKETS: String = AppConstants.HOST_IP + '/ticket/getAllTickets';
  public static readonly GETALL_CONTACTTICKETS: String = AppConstants.HOST_IP + '/ticket/getContactTickets';
  public static readonly GETALL_ORDERTICKETS: String = AppConstants.HOST_IP + '/ticket/getOrderTickets';
  public static readonly GETALL_DEALTICKETS: String = AppConstants.HOST_IP + '/ticket/getDealTickets';
  public static readonly GETALL_ORGANIZATIONTICKETS: String = AppConstants.HOST_IP + '/ticket/getOrganizationTickets';

  public static readonly GETALL_TICKETACTIVITIES: String = AppConstants.HOST_IP + '/ticketactivities';
  public static readonly GETMY_TICKETS: String = AppConstants.HOST_IP + '/ticket';
  public static readonly GET_TICKET: String = AppConstants.HOST_IP + '/ticket/getTicket';
  public static readonly CREATE_TICKET: String = AppConstants.HOST_IP + '/ticket/create';
  public static readonly CREATE_CONTACTTICKET: String = AppConstants.HOST_IP + '/support/contactcreate';
  public static readonly UPDATE_TICKET: String = AppConstants.HOST_IP + '/ticket/update';
  public static readonly DELETE_TICKET: String = AppConstants.HOST_IP + '/ticket/delete';
  public static readonly GET_TICKETDETAILS: String = AppConstants.HOST_IP + '/ticket';

  //Promocodes
  public static readonly GETALL_PROMOCODES: String = AppConstants.HOST_IP + '/promocodes';
  public static readonly GET_PROMOCODE: String = AppConstants.HOST_IP + '/promocode';
  public static readonly PROMOCODESLIST: String = AppConstants.HOST_IP + '/promocodes/getPromocodes?page=1&limit=100';
  public static readonly CREATE_PROMOCODE: String = AppConstants.HOST_IP + '/promocodes';
  public static readonly UPDATE_PROMOCODE: String = AppConstants.HOST_IP + '/promocodes';
  public static readonly DELETE_PROMOCODE: String = AppConstants.HOST_IP + '/promocodes';

  //tickets
  public static readonly GETALL_TICKETCOMMENTS: String = AppConstants.HOST_IP + '/ticketcomments/getAll';
  public static readonly GET_TICKETCOMMENT: String = AppConstants.HOST_IP + '/ticketcomments';
  public static readonly CREATE_TICKETCOMMENT: String = AppConstants.HOST_IP + '/ticketcomments/create';
  public static readonly UPDATE_TICKETCOMMENT: String = AppConstants.HOST_IP + '/ticket/update';
  public static readonly DELETE_TICKETCOMMENT: String = AppConstants.HOST_IP + '/ticket/delete';
  public static readonly DELETE_TICKETCOMMENTIMAGE: String = AppConstants.HOST_IP + '/ticketcommentimages/delete';
  //Contacts
  public static readonly GETALL_CONTACTS: String = AppConstants.HOST_IP + '/contacts';
  public static readonly ALL_CONTACTS_EMAILS: String = AppConstants.HOST_IP + '/contacts/getContacts/workEmails?page=1&limit=100';

  public static readonly GET_CONTACT: String = AppConstants.HOST_IP + '/contacts';
  public static readonly ALL_CONTACTS: String = AppConstants.HOST_IP + '/contacts/getContacts?page=1&limit=100';
  public static readonly ALL_ORG_CONTACTS: String = AppConstants.HOST_IP + '/contacts/getOrgContacts?page=1&limit=100';
  public static readonly CREATE_CONTACT: String = AppConstants.HOST_IP + '/contacts';
  public static readonly UPDATE_CONTACT: String = AppConstants.HOST_IP + '/contacts';
  public static readonly DELETE_CONTACT: String = AppConstants.HOST_IP + '/contacts';
  public static readonly EXPORT_CONTACTS: String = AppConstants.HOST_IP + '/contacts';
  public static readonly IMPORT_CONTACTS: String = AppConstants.HOST_IP + '/contacts';

  //accounts
  public static readonly GETALL_ACCOUNTS: String = AppConstants.HOST_IP + '/accounts';
  public static readonly GET_ACCOUNT: String = AppConstants.HOST_IP + '/accounts';
  public static readonly CREATE_ACCOUNT: String = AppConstants.HOST_IP + '/accounts';
  public static readonly UPDATE_ACCOUNT: String = AppConstants.HOST_IP + '/accounts';
  public static readonly DELETE_ACCOUNT: String = AppConstants.HOST_IP + '/accounts';
  public static readonly GETALL: String = AppConstants.HOST_IP + '/accounts/getaccounts';

  //categories
  public static readonly GETALL_CATEGORYS: String = AppConstants.HOST_IP + '/categories';
  public static readonly GET_CATEGORY: String = AppConstants.HOST_IP + '/categories';
  public static readonly CREATE_CATEGORY: String = AppConstants.HOST_IP + '/categories';
  public static readonly UPDATE_CATEGORY: String = AppConstants.HOST_IP + '/categories';
  public static readonly DELETE_CATEGORY: String = AppConstants.HOST_IP + '/categories';
  //feeds
  public static readonly GETALL_FEEDS: String = AppConstants.HOST_IP + '/feeds';
  public static readonly GET_FEED: String = AppConstants.HOST_IP + '/feeds';
  public static readonly CREATE_FEED: String = AppConstants.HOST_IP + '/feeds';
  public static readonly UPDATE_FEED: String = AppConstants.HOST_IP + '/feeds';
  public static readonly DELETE_FEED: String = AppConstants.HOST_IP + '/feeds';

  //Library
  public static readonly GETALL_LIBRARYS: String = AppConstants.HOST_IP + '/library';
  public static readonly GET_LIBRARY: String = AppConstants.HOST_IP + '/library';
  public static readonly CREATE_LIBRARY: String = AppConstants.HOST_IP + '/library';
  public static readonly UPDATE_LIBRARY: String = AppConstants.HOST_IP + '/library';
  public static readonly DELETE_LIBRARY: String = AppConstants.HOST_IP + '/library';
  public static readonly GET_LIBRARY_POST_DETAIL: String = AppConstants.HOST_IP + '/library/postDetail';
  public static readonly GET_LIBRARY_POST_ACCOUNTS: String = AppConstants.HOST_IP + '/library/postAccounts';
  public static readonly ALL_LIBRARY_POSTS: String = AppConstants.HOST_IP + '/library/posts';
  public static readonly LIBRARY_POSTCONTENT: String = AppConstants.HOST_IP + '/library/postcontent';

  //posts
  public static readonly GETALL_POSTS: String = AppConstants.HOST_IP + '/posts';
  public static readonly GET_POSTS: String = AppConstants.HOST_IP + '/posts';
  public static readonly CREATE_POSTS: String = AppConstants.HOST_IP + '/posts';
  public static readonly UPDATE_POSTS: String = AppConstants.HOST_IP + '/posts';
  public static readonly DELETE_POSTS: String = AppConstants.HOST_IP + '/posts';
  public static readonly ALL_POSTS: String = AppConstants.HOST_IP + '/posts/getPosts';

  //louises
  public static readonly GETALL_LOUISES: String = AppConstants.HOST_IP + '/louise';
  public static readonly GET_LOUISE: String = AppConstants.HOST_IP + '/louise';
  public static readonly CREATE_LOUISE: String = AppConstants.HOST_IP + '/louise';
  public static readonly UPDATE_LOUISE: String = AppConstants.HOST_IP + '/louise';
  public static readonly DELETE_LOUISE: String = AppConstants.HOST_IP + '/louise';
  //ContactFiles
  public static readonly GETALL_CONTACTFILES: String = AppConstants.HOST_IP + '/contacts';
  public static readonly GET_CONTACTFILE: String = AppConstants.HOST_IP + '/contacts';
  public static readonly CREATE_CONTACTFILE: String = AppConstants.HOST_IP + '/contacts';
  public static readonly UPDATE_CONTACTFILE: String = AppConstants.HOST_IP + '/contacts';
  public static readonly DELETE_CONTACTFILE: String = AppConstants.HOST_IP + '/contacts';

  // ContactEmails
  public static readonly CREATE_CONTACTEMAIL: String = AppConstants.HOST_IP + '/contacts';
  public static readonly GETALL_CONTACTEMAILS: String = AppConstants.HOST_IP + '/contacts';
  public static readonly UPDATE_CONTACTEMAIL: String = AppConstants.HOST_IP + '/contacts';

  public static readonly GET_CONTACTEMAIL: String = AppConstants.HOST_IP + '/contacts';


  //Tasks
  public static readonly GETALL_TASKS_WITHQUERY: String = AppConstants.HOST_IP + '/tasks/allTaskswithQuery';
  public static readonly GETALL_TASKS: String = AppConstants.HOST_IP + '/tasks/allTasks';
  public static readonly GET_TASK: String = AppConstants.HOST_IP + '/tasks';
  public static readonly CREATE_TASK: String = AppConstants.HOST_IP + '/tasks';
  public static readonly UPDATE_TASK: String = AppConstants.HOST_IP + '/tasks';
  public static readonly DELETE_TASK: String = AppConstants.HOST_IP + '/tasks';
  public static readonly GETALL_EVENTS: String = AppConstants.HOST_IP + '/events/allEvents';
  public static readonly GET_EVENTS: String = AppConstants.HOST_IP + '/events/getDetails';
  public static readonly CREATE_EVENT: String = AppConstants.HOST_IP + '/events';
  public static readonly GETALL_MEETINGS: String = AppConstants.HOST_IP + '/events/allMeetings';
  public static readonly GETALL_MEETINGSBYDATE: String = AppConstants.HOST_IP + '/events';
  public static readonly GETALL_TASK_ACTIVITIES: String = AppConstants.HOST_IP + '/activities/tasks';


  //OrderFiles
  public static readonly GETALL_ORDERFILES: String = AppConstants.HOST_IP + '/orders';
  public static readonly GET_ORDERFILE: String = AppConstants.HOST_IP + '/orders';
  public static readonly CREATE_ORDERFILE: String = AppConstants.HOST_IP + '/orders';
  public static readonly UPDATE_ORDERFILE: String = AppConstants.HOST_IP + '/orders';
  public static readonly DELETE_ORDERFILE: String = AppConstants.HOST_IP + '/orders';

  //TicketFiles
  public static readonly GETALL_TICKETFILES: String = AppConstants.HOST_IP + '/ticket';
  public static readonly GET_TICKETFILE: String = AppConstants.HOST_IP + '/ticket';
  public static readonly CREATE_TICKETFILE: String = AppConstants.HOST_IP + '/ticket';
  public static readonly UPDATE_TICKETFILE: String = AppConstants.HOST_IP + '/ticket';
  public static readonly DELETE_TICKETFILE: String = AppConstants.HOST_IP + '/ticket';

  //Pipelines
  public static readonly GETALL_PIPELINES: String = AppConstants.HOST_IP + '/pipelines';
  public static readonly GET_PIPELINE: String = AppConstants.HOST_IP + '/pipelines';
  public static readonly CREATE_PIPELINE: String = AppConstants.HOST_IP + '/pipelines';
  public static readonly UPDATE_PIPELINE: String = AppConstants.HOST_IP + '/pipelines';
  public static readonly DELETE_PIPELINE: String = AppConstants.HOST_IP + '/pipelines';


  //PACKAGESUBSCRIBED
  public static readonly GETALL_PACKAGESUBSCRIBEDS: String = AppConstants.HOST_IP + '/packagesubscribed';
  public static readonly GET_PACKAGESUBSCRIBED: String = AppConstants.HOST_IP + '/packagesubscribed';
  public static readonly CREATE_PACKAGESUBSCRIBED: String = AppConstants.HOST_IP + '/packagesubscribed';
  public static readonly UPDATE_PACKAGESUBSCRIBED: String = AppConstants.HOST_IP + '/packagesubscribed';
  public static readonly DELETE_PACKAGESUBSCRIBED: String = AppConstants.HOST_IP + '/packagesubscribed';

  //Forecast
  public static readonly GETALL_FORECAST: String = AppConstants.HOST_IP + '/dealforecasts';
  public static readonly GET_FORECAST: String = AppConstants.HOST_IP + '/dealforecasts';
  public static readonly CREATE_FORECAST: String = AppConstants.HOST_IP + '/dealforecasts';
  public static readonly UPDATE_FORECAST: String = AppConstants.HOST_IP + '/dealforecasts';
  public static readonly DELETE_FORECAST: String = AppConstants.HOST_IP + '/dealforecasts';

  //PIPELINESTAGES
  public static readonly GETALL_PIPELINESTAGES: String = AppConstants.HOST_IP + '/pipelines';
  public static readonly GET_PIPELINESTAGE: String = AppConstants.HOST_IP + '/pipelines';
  public static readonly CREATE_PIPELINESTAGE: String = AppConstants.HOST_IP + '/pipelines';
  public static readonly UPDATE_PIPELINESTAGE: String = AppConstants.HOST_IP + '/pipelines';
  public static readonly DELETE_PIPELINESTAGE: String = AppConstants.HOST_IP + '/pipelines';

  //Dashboard
  public static readonly GET_DASHBOARD: String = AppConstants.HOST_IP + '/dashboard/users';
  public static readonly CREATE_DASHBOARD: String = AppConstants.HOST_IP + '/dashboard';
  public static readonly GET_DEALSTATS: String = AppConstants.HOST_IP + '/dealStatistics';
  public static readonly DASH_STAT: String = AppConstants.HOST_IP + '/dealstatistics';
  public static readonly GET_SPECIFICDEALS: String = AppConstants.HOST_IP + '/dealStatistics';
  public static readonly GET_TODAYEVENTS: String = AppConstants.HOST_IP + '/dealStatistics';
  public static readonly GET_TOMORROWEVENTS: String = AppConstants.HOST_IP + '/dealStatistics';
  public static readonly GET_DAYAFERTOMORROWEVENTS: String = AppConstants.HOST_IP + '/dealStatistics';

  //Domain
  public static readonly CREATE_DOMAIN_CERT: String = AppConstants.HOST_IP + '/domain/customerDomainCertificate';
  public static readonly ACTIVATE_BUCKET_FOR_CUSTOMER: String = AppConstants.HOST_IP + '/domain/activateBucketForCustomer';
  public static readonly CUSTOMER_DOMAIN_DETAILS: String = AppConstants.HOST_IP + '/domain/customerDomainDetails';
  public static readonly CUSTOMER_AVAILABEL_DOMAIN_DETAILS: String = AppConstants.HOST_IP + '/domain/availableDomainsForCustomer';

  //STAGES
  public static readonly GETALL_STAGES: String = AppConstants.HOST_IP + '/stages';
  public static readonly GET_STAGE: String = AppConstants.HOST_IP + '/stages';
  public static readonly CREATE_STAGE: String = AppConstants.HOST_IP + '/stages';
  public static readonly UPDATE_STAGE: String = AppConstants.HOST_IP + '/stages';
  public static readonly DELETE_STAGE: String = AppConstants.HOST_IP + '/stages';

  //Roles
  public static readonly GETALL_ROLES: String = AppConstants.HOST_IP + '/roles';
  public static readonly GETALL_ENABLED_ROLES: String = AppConstants.HOST_IP + '/roles/enabledRoles?page=1&limit=100';
  public static readonly GET_ROLE: String = AppConstants.HOST_IP + '/roles';
  public static readonly CREATE_ROLE: String = AppConstants.HOST_IP + '/roles';
  public static readonly UPDATE_ROLE: String = AppConstants.HOST_IP + '/roles';
  public static readonly DELETE_ROLE: String = AppConstants.HOST_IP + '/roles';

  // Integrations
  public static readonly CREATE_INTEGRATION: String = AppConstants.HOST_IP + '/integrations';
  public static readonly GETALL_INTEGRATIONS: String = AppConstants.HOST_IP + '/integrations';
  public static readonly GET_INTEGRATION: String = AppConstants.HOST_IP + '/integrations';
  public static readonly DELETE_INTEGRATION: String = AppConstants.HOST_IP + '/integrations';
  public static readonly UPDATE_INTEGRATION: String = AppConstants.HOST_IP + '/integrations';


  //UserRoles
  public static readonly GETALL_USERROLES: String = AppConstants.HOST_IP + '/users';
  public static readonly GET_USERROLE: String = AppConstants.HOST_IP + '/users';
  public static readonly CREATE_USERROLE: String = AppConstants.HOST_IP + '/users';
  public static readonly UPDATE_USERROLE: String = AppConstants.HOST_IP + '/users';
  public static readonly DELETE_USERROLE: String = AppConstants.HOST_IP + '/users';

  // UserTeams
  public static readonly GETALL_USERTEAMS: String = AppConstants.HOST_IP + '/users';
  public static readonly CREATE_USERTEAMS: String = AppConstants.HOST_IP + '/users';
  public static readonly GET_USERTEAMS: String = AppConstants.HOST_IP + '/users';
  public static readonly UPDATE_USERTEAMS: String = AppConstants.HOST_IP + '/users';
  public static readonly DELETE_USERTEAMS: String = AppConstants.HOST_IP + '/users';
  public static readonly TEAMSLIST: String = AppConstants.HOST_IP + '/users';

  //ContactActivity
  public static readonly GETALL_CONTACTACTIVITY: String = AppConstants.HOST_IP + '/contacts';
  public static readonly GET_CONTACTACTIVITY: String = AppConstants.HOST_IP + '/contacts';
  public static readonly CREATE_CONTACTACTIVITY: String = AppConstants.HOST_IP + '/contacts';
  public static readonly UPDATE_CONTACTACTIVITY: String = AppConstants.HOST_IP + '/contacts';
  public static readonly DELETE_CONTACTACTIVITY: String = AppConstants.HOST_IP + '/contacts';


  //ContactTimeline
  public static readonly GETALL_CONTACTTIMELINE: String = AppConstants.HOST_IP + '/contacts';
  public static readonly GET_CONTACTTIMELINE: String = AppConstants.HOST_IP + '/contacts';
  public static readonly CREATE_CONTACTTIMELINE: String = AppConstants.HOST_IP + '/contacts';
  public static readonly UPDATE_CONTACTTIMELINE: String = AppConstants.HOST_IP + '/contacts';
  public static readonly DELETE_CONTACTTIMELINE: String = AppConstants.HOST_IP + '/contacts';

  //DealTimeline
  public static readonly GETALL_DEALTIMELINE: String = AppConstants.HOST_IP + '/deals';
  public static readonly GET_DEALTIMELINE: String = AppConstants.HOST_IP + '/deals';
  public static readonly CREATE_DEALTIMELINE: String = AppConstants.HOST_IP + '/deals';
  public static readonly UPDATE_DEALTIMELINE: String = AppConstants.HOST_IP + '/deals';
  public static readonly DELETE_DEALTIMELINE: String = AppConstants.HOST_IP + '/deals';

  //Principalcompanydetails
  public static readonly GETALL_TENANTDETAILS: String = AppConstants.HOST_IP + '/tenantdetails';
  public static readonly GET_TENANTDETAIL: String = AppConstants.HOST_IP + '/tenantdetails';
  public static readonly CREATE_TENANTDETAIL: String = AppConstants.HOST_IP + '/tenantdetails';
  public static readonly UPDATE_TENANTDETAIL: String = AppConstants.HOST_IP + '/tenantdetails';
  public static readonly DELETE_TENANTDETAIL: String = AppConstants.HOST_IP + '/tenantdetails';

  //UserPayments
  public static readonly GETALL_USERPAYMENTS: String = AppConstants.HOST_IP + '/users';
  public static readonly GET_USERPAYMENT: String = AppConstants.HOST_IP + '/users';
  public static readonly CREATE_USERPAYMENT: String = AppConstants.HOST_IP + '/users';
  public static readonly UPDATE_USERPAYMENT: String = AppConstants.HOST_IP + '/users';
  public static readonly DELETE_USERPAYMENT: String = AppConstants.HOST_IP + '/users';

  //Accessroleassignment
  public static readonly GETALL_ACCESSROLEASSIGNMENTS: String = AppConstants.HOST_IP + '/roles';
  public static readonly GET_ACCESSROLEASSIGNMENT: String = AppConstants.HOST_IP + '/roles';
  public static readonly CREATE_ACCESSROLEASSIGNMENT: String = AppConstants.HOST_IP + '/roles';
  public static readonly UPDATE_ACCESSROLEASSIGNMENT: String = AppConstants.HOST_IP + '/roles';
  public static readonly DELETE_ACCESSROLEASSIGNMENT: String = AppConstants.HOST_IP + '/roles';


  //usernotes
  public static readonly GETALL_USERNOTES: String = AppConstants.HOST_IP + '/users';
  public static readonly GET_USERNOTE: String = AppConstants.HOST_IP + '/users';
  public static readonly CREATE_USERNOTE: String = AppConstants.HOST_IP + '/users';
  public static readonly UPDATE_USERNOTE: String = AppConstants.HOST_IP + '/users';
  public static readonly DELETE_USERNOTE: String = AppConstants.HOST_IP + '/users';

  //twitter
  public static readonly POST_TO_TWITTER: String = AppConstants.HOST_IP + '/twitterpost';
  public static readonly REVOKE_TWITTER: String = AppConstants.HOST_IP + '/twitterpost/revoketoken';
  //contactaudits
  public static readonly GETALL_CONTACTAUDITS: String = AppConstants.HOST_IP + '/contacts';
  public static readonly GET_CONTACTAUDIT: String = AppConstants.HOST_IP + '/contacts';
  public static readonly CREATE_CONTACTAUDIT: String = AppConstants.HOST_IP + '/contacts';
  public static readonly UPDATE_CONTACTAUDIT: String = AppConstants.HOST_IP + '/contacts';
  public static readonly DELETE_CONTACTAUDIT: String = AppConstants.HOST_IP + '/contacts';

  //dealaudits
  public static readonly GETALL_DEALAUDITS: String = AppConstants.HOST_IP + '/deals';
  public static readonly GET_DEALAUDIT: String = AppConstants.HOST_IP + '/deals';
  public static readonly CREATE_DEALAUDIT: String = AppConstants.HOST_IP + '/deals';
  public static readonly UPDATE_DEALAUDIT: String = AppConstants.HOST_IP + '/deals';
  public static readonly DELETE_DEALAUDIT: String = AppConstants.HOST_IP + '/deals';

  public static readonly GETALL_TICKETACTIVITY: String = AppConstants.HOST_IP + '/ticket';
  public static readonly GETALL_ORDERACTIVITY: String = AppConstants.HOST_IP + '/orders';


  //dealactivities
  public static readonly GETALL_DEALACTIVITIES: String = AppConstants.HOST_IP + '/deals';
  public static readonly GET_DEALACTIVITY: String = AppConstants.HOST_IP + '/deals';
  public static readonly CREATE_DEALACTIVITY: String = AppConstants.HOST_IP + '/deals';
  public static readonly UPDATE_DEALACTIVITY: String = AppConstants.HOST_IP + '/deals';
  public static readonly DELETE_DEALACTIVITY: String = AppConstants.HOST_IP + '/deals';

  //organizationactivities
  public static readonly GETALL_ORGANIZATIONACTIVITIES: String = AppConstants.HOST_IP + '/organizations';
  public static readonly GET_ORGANIZATIONACTIVITY: String = AppConstants.HOST_IP + '/organizations';
  public static readonly CREATE_ORGANIZATIONACTIVITY: String = AppConstants.HOST_IP + '/organizations';
  public static readonly UPDATE_ORGANIZATIONACTIVITY: String = AppConstants.HOST_IP + '/organizations';
  public static readonly DELETE_ORGANIZATIONACTIVITY: String = AppConstants.HOST_IP + '/organizations';
  //dealgroups
  public static readonly GETALL_DEALGROUPS: String = AppConstants.HOST_IP + '/deals';
  public static readonly GET_DEALGROUP: String = AppConstants.HOST_IP + '/deals';
  public static readonly CREATE_DEALGROUP: String = AppConstants.HOST_IP + '/deals';
  public static readonly UPDATE_DEALGROUP: String = AppConstants.HOST_IP + '/deals';
  public static readonly DELETE_DEALGROUP: String = AppConstants.HOST_IP + '/deals';

  //groups
  public static readonly GETALL_GROUPS: String = AppConstants.HOST_IP + '/groups';
  public static readonly SHOW_GROUPS: String = AppConstants.HOST_IP + '/groups/getgroups';
  public static readonly GET_GROUP: String = AppConstants.HOST_IP + '/groups';
  public static readonly CREATE_GROUP: String = AppConstants.HOST_IP + '/groups';
  public static readonly UPDATE_GROUP: String = AppConstants.HOST_IP + '/groups';
  public static readonly DELETE_GROUP: String = AppConstants.HOST_IP + '/groups';

  //organizationaudits
  public static readonly GETALL_ORGANIZATIONAUDITS: String = AppConstants.HOST_IP + '/organizations';
  public static readonly GET_ORGANIZATIONAUDIT: String = AppConstants.HOST_IP + '/organizations';
  public static readonly CREATE_ORGANIZATIONAUDIT: String = AppConstants.HOST_IP + '/organizations';
  public static readonly UPDATE_ORGANIZATIONAUDIT: String = AppConstants.HOST_IP + '/organizations';
  public static readonly DELETE_ORGANIZATIONAUDIT: String = AppConstants.HOST_IP + '/organizations';

  //uicomponents
  public static readonly GETALL_UICOMPONENTS: String = AppConstants.HOST_IP + '/uicomponents';
  public static readonly GET_UICOMPONENT: String = AppConstants.HOST_IP + '/uicomponents';
  public static readonly GETALL_ENABLED_ACCESSROLES: String = AppConstants.HOST_IP + '/uicomponents/enabledAccess?page=1&limit=100';
  public static readonly CREATE_UICOMPONENT: String = AppConstants.HOST_IP + '/uicomponents';
  public static readonly UPDATE_UICOMPONENT: String = AppConstants.HOST_IP + '/uicomponents';
  public static readonly DELETE_UICOMPONENT: String = AppConstants.HOST_IP + '/uicomponents';

  //useraccounts
  public static readonly GETALL_USERACCOUNTS: String = AppConstants.HOST_IP + '/useraccounts';
  public static readonly GET_USERACCOUNT: String = AppConstants.HOST_IP + '/useraccounts';
  public static readonly CREATE_USERACCOUNT: String = AppConstants.HOST_IP + '/useraccounts';
  public static readonly UPDATE_USERACCOUNT: String = AppConstants.HOST_IP + '/useraccounts';
  public static readonly DELETE_USERACCOUNT: String = AppConstants.HOST_IP + '/useraccounts';

  //tenantdetails
  public static readonly GETALL_USERPRINCIPALDETAILS: String = AppConstants.HOST_IP + '/tenantdetails?page=1&limit=100';
  public static readonly GET_USERPRINCIPALDETAIL: String = AppConstants.HOST_IP + '/tenantdetails';
  public static readonly CREATE_USERPRINCIPALDETAIL: String = AppConstants.HOST_IP + '/tenantdetails';
  public static readonly UPDATE_USERPRINCIPALDETAIL: String = AppConstants.HOST_IP + '/tenantdetails';
  public static readonly DELETE_USERPRINCIPALDETAIL: String = AppConstants.HOST_IP + '/tenantdetails';

  //contactevents
  public static readonly GETALL_CONTACTEVENTS: String = AppConstants.HOST_IP + '/contacts';
  public static readonly GET_CONTACTEVENT: String = AppConstants.HOST_IP + '/contacts';
  public static readonly CREATE_CONTACTEVENT: String = AppConstants.HOST_IP + '/contacts';
  public static readonly UPDATE_CONTACTEVENT: String = AppConstants.HOST_IP + '/contacts';
  public static readonly DELETE_CONTACTEVENT: String = AppConstants.HOST_IP + '/contacts';


  //contactevents
  public static readonly GETALL_TICKETEVENTS: String = AppConstants.HOST_IP + '/ticket';
  //public static readonly GETALL_TICKETEVENTS: String = AppConstants.HOST_IP + '/contactevents?page=1&limit=100';
  public static readonly GET_TICKETEVENT: String = AppConstants.HOST_IP + '/ticket';
  public static readonly CREATE_TICKETEVENT: String = AppConstants.HOST_IP + '/ticket';
  public static readonly UPDATE_TICKETEVENT: String = AppConstants.HOST_IP + '/ticket';
  public static readonly DELETE_TICKETEVENT: String = AppConstants.HOST_IP + '/ticket';

  //contactnotes
  public static readonly GETALL_CONTACTNOTES: String = AppConstants.HOST_IP + '/contacts';
  public static readonly GET_CONTACTNOTE: String = AppConstants.HOST_IP + '/contacts';
  public static readonly CREATE_CONTACTNOTE: String = AppConstants.HOST_IP + '/contacts';
  public static readonly UPDATE_CONTACTNOTE: String = AppConstants.HOST_IP + '/contacts';
  public static readonly DELETE_CONTACTNOTE: String = AppConstants.HOST_IP + '/contacts';

  //ticketnotes
  public static readonly GETALL_TICKETNOTES: String = AppConstants.HOST_IP + '/ticket';
  public static readonly GET_TICKETNOTE: String = AppConstants.HOST_IP + '/ticket';
  public static readonly CREATE_TICKETNOTE: String = AppConstants.HOST_IP + '/ticket';
  public static readonly UPDATE_TICKETNOTE: String = AppConstants.HOST_IP + '/ticket';
  public static readonly DELETE_TICKETNOTE: String = AppConstants.HOST_IP + '/ticket';

  public static readonly GETALL_TICKETEMAILS: String = AppConstants.HOST_IP + '/ticket';
  public static readonly DELETE_TICKETEMAILS: String = AppConstants.HOST_IP + '/ticket';
  public static readonly CREATE_TICKETEMAILS: String = AppConstants.HOST_IP + '/ticket';

  //contacttasks
  public static readonly GETALL_CONTACTTASKS: String = AppConstants.HOST_IP + '/contacts';
  public static readonly GET_CONTACTTASK: String = AppConstants.HOST_IP + '/contacts';
  public static readonly CREATE_CONTACTTASK: String = AppConstants.HOST_IP + '/contacts';
  public static readonly UPDATE_CONTACTTASK: String = AppConstants.HOST_IP + '/contacts';
  public static readonly DELETE_CONTACTTASK: String = AppConstants.HOST_IP + '/contacts';

  //tickettasks
  public static readonly GETALL_TICKETTASKS: String = AppConstants.HOST_IP + '/ticket';
  public static readonly GET_TICKETTASK: String = AppConstants.HOST_IP + '/ticket';
  public static readonly CREATE_TICKETTASK: String = AppConstants.HOST_IP + '/ticket';
  public static readonly UPDATE_TICKETTASK: String = AppConstants.HOST_IP + '/ticket';
  public static readonly DELETE_TICKETTASK: String = AppConstants.HOST_IP + '/ticket';

  //dealevents
  public static readonly GETALL_DEALEVENTS: String = AppConstants.HOST_IP + '/deals';
  public static readonly GET_DEALEVENT: String = AppConstants.HOST_IP + '/deals';
  public static readonly CREATE_DEALEVENT: String = AppConstants.HOST_IP + '/deals';
  public static readonly UPDATE_DEALEVENT: String = AppConstants.HOST_IP + '/deals';
  public static readonly DELETE_DEALEVENT: String = AppConstants.HOST_IP + '/deals';

  //dealemails
  public static readonly CREATE_DEALEMAIL: String = AppConstants.HOST_IP + '/deals';
  public static readonly GETALL_DEALEMAILS: String = AppConstants.HOST_IP + '/deals';
  public static readonly UPDATE_DEALEMAIL: String = AppConstants.HOST_IP + '/deals';
  public static readonly DELETE_DEALEMAIL: String = AppConstants.HOST_IP + '/deals';
  public static readonly GET_DEALEMAIL: String = AppConstants.HOST_IP + '/deals';



  //dealfiles
  public static readonly GETALL_DEALFILES: String = AppConstants.HOST_IP + '/deals';
  public static readonly GET_DEALFILE: String = AppConstants.HOST_IP + '/deals';
  public static readonly CREATE_DEALFILE: String = AppConstants.HOST_IP + '/deals';
  public static readonly UPDATE_DEALFILE: String = AppConstants.HOST_IP + '/deals';
  public static readonly DELETE_DEALFILE: String = AppConstants.HOST_IP + '/deals';


  //dealnotes
  public static readonly GETALL_DEALNOTES: String = AppConstants.HOST_IP + '/deals';
  public static readonly GET_DEALNOTE: String = AppConstants.HOST_IP + '/deals';
  public static readonly CREATE_DEALNOTE: String = AppConstants.HOST_IP + '/deals';
  public static readonly UPDATE_DEALNOTE: String = AppConstants.HOST_IP + '/deals';
  public static readonly DELETE_DEALNOTE: String = AppConstants.HOST_IP + '/deals';

  //ordernotes
  public static readonly GETALL_ORDERNOTES: String = AppConstants.HOST_IP + '/orders';
  public static readonly GET_ORDERGRAPHSTATS: String = AppConstants.HOST_IP + '/orderGraphStats';
  public static readonly GETALL_ORDERACTIVITIES: String = AppConstants.HOST_IP + '/orderactivities';
  public static readonly GET_ORDERNOTE: String = AppConstants.HOST_IP + '/orders';
  public static readonly CREATE_ORDERNOTE: String = AppConstants.HOST_IP + '/orders';
  public static readonly UPDATE_ORDERNOTE: String = AppConstants.HOST_IP + '/orders';
  public static readonly DELETE_ORDERNOTE: String = AppConstants.HOST_IP + '/orders';

  //orderTASKs
  public static readonly GETALL_ORDERTASKS: String = AppConstants.HOST_IP + '/orders';
  public static readonly GET_ORDERTASK: String = AppConstants.HOST_IP + '/orders';
  public static readonly CREATE_ORDERTASK: String = AppConstants.HOST_IP + '/orders';
  public static readonly UPDATE_ORDERTASK: String = AppConstants.HOST_IP + '/orders';
  public static readonly DELETE_ORDERTASK: String = AppConstants.HOST_IP + '/orders';

  //orderEVENTs
  public static readonly GETALL_ORDEREVENTS: String = AppConstants.HOST_IP + '/orders';
  public static readonly GET_ORDEREVENT: String = AppConstants.HOST_IP + '/orders';
  public static readonly CREATE_ORDEREVENT: String = AppConstants.HOST_IP + '/orders';
  public static readonly UPDATE_ORDEREVENT: String = AppConstants.HOST_IP + '/orders';
  public static readonly DELETE_ORDEREVENT: String = AppConstants.HOST_IP + '/orders';

  //dealtasks
  public static readonly GETALL_DEALTASKS: String = AppConstants.HOST_IP + '/deals';
  public static readonly GET_DEALTASK: String = AppConstants.HOST_IP + '/deals';
  public static readonly CREATE_DEALTASK: String = AppConstants.HOST_IP + '/deals';
  public static readonly UPDATE_DEALTASK: String = AppConstants.HOST_IP + '/deals';
  public static readonly DELETE_DEALTASK: String = AppConstants.HOST_IP + '/deals';

  //organizationevents
  public static readonly GETALL_ORGANIZATIONEVENTS: String = AppConstants.HOST_IP + '/organizations';
  public static readonly GET_ORGANIZATIONEVENT: String = AppConstants.HOST_IP + '/organizations';
  public static readonly CREATE_ORGANIZATIONEVENT: String = AppConstants.HOST_IP + '/organizations';
  public static readonly UPDATE_ORGANIZATIONEVENT: String = AppConstants.HOST_IP + '/organizations';
  public static readonly DELETE_ORGANIZATIONEVENT: String = AppConstants.HOST_IP + '/organizations';

  //organizationfiles
  public static readonly GETALL_ORGANIZATIONFILES: String = AppConstants.HOST_IP + '/organizations';
  public static readonly GET_ORGANIZATIONFILE: String = AppConstants.HOST_IP + '/organizations';
  public static readonly CREATE_ORGANIZATIONFILE: String = AppConstants.HOST_IP + '/organizations';
  public static readonly UPDATE_ORGANIZATIONFILE: String = AppConstants.HOST_IP + '/organizations';
  public static readonly DELETE_ORGANIZATIONFILE: String = AppConstants.HOST_IP + '/organizations';

  //organizationnotes
  public static readonly GETALL_ORGANIZATIONNOTES: String = AppConstants.HOST_IP + '/organizations';
  public static readonly GET_ORGANIZATIONNOTE: String = AppConstants.HOST_IP + '/organizations';
  public static readonly CREATE_ORGANIZATIONNOTE: String = AppConstants.HOST_IP + '/organizations';
  public static readonly UPDATE_ORGANIZATIONNOTE: String = AppConstants.HOST_IP + '/organizations';
  public static readonly DELETE_ORGANIZATIONNOTE: String = AppConstants.HOST_IP + '/organizations';

  //organizationemails
  public static readonly GETALL_ORGANIZATIONEMAIL: String = AppConstants.HOST_IP + '/organizations';
  // public static readonly GET_ORGANIZATIONNOTE: String = AppConstants.HOST_IP + '/organizations';
  public static readonly CREATE_ORGANIZATIONEMAIL: String = AppConstants.HOST_IP + '/organizations';
  public static readonly DELETE_ORGANIZATIONEMAIL: String = AppConstants.HOST_IP + '/organizations';
  public static readonly GET_ORGANIZATIONEMAIL: String = AppConstants.HOST_IP + '/organizations';


  //organizationtasks
  public static readonly GETALL_ORGANIZATIONTASKS: String = AppConstants.HOST_IP + '/organizations';
  public static readonly GET_ORGANIZATIONTASK: String = AppConstants.HOST_IP + '/organizations';
  public static readonly CREATE_ORGANIZATIONTASK: String = AppConstants.HOST_IP + '/organizations';
  public static readonly UPDATE_ORGANIZATIONTASK: String = AppConstants.HOST_IP + '/organizations';
  public static readonly DELETE_ORGANIZATIONTASK: String = AppConstants.HOST_IP + '/organizations';


  //taskreports
  public static readonly GETALL_TASKREPORTS: String = AppConstants.HOST_IP + '/taskreports';
  public static readonly GET_TASKREPORT: String = AppConstants.HOST_IP + '/taskreports';
  public static readonly CREATE_TASKREPORT: String = AppConstants.HOST_IP + '/taskreports';
  public static readonly UPDATE_TASKREPORT: String = AppConstants.HOST_IP + '/taskreports';
  public static readonly DELETE_TASKREPORT: String = AppConstants.HOST_IP + '/taskreports';
  public static readonly GETALL_EVENTREPORTS: String = AppConstants.HOST_IP + '/eventreports/getEventReports';
  public static readonly GETALL_NOTEREPORTS: String = AppConstants.HOST_IP + '/notereports/getNoteReports';
  public static readonly GETALL_FILEREPORTS: String = AppConstants.HOST_IP + '/filereports/getFileReports';
  public static readonly GETALL_NOTES_REPORTS: String = AppConstants.HOST_IP + '/activities/notes';
  public static readonly GETALL_EVENTS_REPORTS: String = AppConstants.HOST_IP + '/activities/events';
  public static readonly GETALL_FILE_REPORTS: String = AppConstants.HOST_IP + '/activities/files';
  public static readonly GETALL_ACTIVITIES: String = AppConstants.HOST_IP + '/activities';

  //usergroups
  public static readonly GETALL_USERGROUPS: String = AppConstants.HOST_IP + '/usergroups';
  public static readonly GET_USERGROUP: String = AppConstants.HOST_IP + '/users/groups';
  public static readonly CREATE_USERGROUP: String = AppConstants.HOST_IP + '/users';
  public static readonly UPDATE_USERGROUP: String = AppConstants.HOST_IP + '/usergroups';
  public static readonly DELETE_USERGROUP: String = AppConstants.HOST_IP + '/users';

  //activityreports
  public static readonly GETALL_ACTIVITYREPORTS: String = AppConstants.HOST_IP + '/activityreports';
  public static readonly GETMY_ACTIVITYREPORTS: String = AppConstants.HOST_IP + '/activityreports';
  public static readonly GET_ACTIVITYREPORT: String = AppConstants.HOST_IP + '/activityreports';
  public static readonly CREATE_ACTIVITYREPORT: String = AppConstants.HOST_IP + '/activityreports';
  public static readonly UPDATE_ACTIVITYREPORT: String = AppConstants.HOST_IP + '/activityreports';
  public static readonly DELETE_ACTIVITYREPORT: String = AppConstants.HOST_IP + '/activityreports';

  //Users
  public static readonly GETALL_MYTASKS: String = AppConstants.HOST_IP + '/users';
  public static readonly GETALL_MYEVENTS: String = AppConstants.HOST_IP + '/users';
  public static readonly GETALL_MYDEALS: String = AppConstants.HOST_IP + '/users';

  //useralerts
  public static readonly GETALL_USERALERTS: String = AppConstants.HOST_IP + '/useralerts';
  public static readonly GET_USERALERT: String = AppConstants.HOST_IP + '/useralerts';
  public static readonly CREATE_USERALERT: String = AppConstants.HOST_IP + '/useralerts';
  public static readonly UPDATE_USERALERT: String = AppConstants.HOST_IP + '/useralerts';
  public static readonly DELETE_USERALERT: String = AppConstants.HOST_IP + '/useralerts';

  //userfiles
  public static readonly GETALL_USERFILES: String = AppConstants.HOST_IP + '/users';
  public static readonly GET_USERFILE: String = AppConstants.HOST_IP + '/users';
  public static readonly CREATE_USERFILE: String = AppConstants.HOST_IP + '/users';
  public static readonly UPDATE_USERFILE: String = AppConstants.HOST_IP + '/users';
  public static readonly DELETE_USERFILE: String = AppConstants.HOST_IP + '/users';

  //usernotifications
  public static readonly GETALL_USERNOTIFICATIONS: String = AppConstants.HOST_IP + '/users';
  public static readonly GET_USERNOTIFICATION: String = AppConstants.HOST_IP + '/usernotifications';
  public static readonly CREATE_USERNOTIFICATION: String = AppConstants.HOST_IP + '/users';
  public static readonly UPDATE_USERNOTIFICATION: String = AppConstants.HOST_IP + '/usernotifications';
  public static readonly DELETE_USERNOTIFICATION: String = AppConstants.HOST_IP + '/usernotifications';

  //orders
  public static readonly GETALL_ORDERS: String = AppConstants.HOST_IP + '/orders';
  public static readonly GETALL_CONTACTODERS: String = AppConstants.HOST_IP + '/orders/getContactOrders';
  public static readonly GETALL_DEALODERS: String = AppConstants.HOST_IP + '/orders/getDealOrders';
  public static readonly GETALL_ORGANIZATIONODERS: String = AppConstants.HOST_IP + '/orders/getOrganizationOrders';
  public static readonly GETALL_TICKETODERS: String = AppConstants.HOST_IP + '/orders/getTicketOrders';

  public static readonly GETMY_ORDERS: String = AppConstants.HOST_IP + '/orders';
  public static readonly GET_ORDER: String = AppConstants.HOST_IP + '/orders';
  public static readonly CREATE_ORDER: String = AppConstants.HOST_IP + '/orders';
  public static readonly UPDATE_ORDER: String = AppConstants.HOST_IP + '/orders';
  public static readonly DELETE_ORDER: String = AppConstants.HOST_IP + '/orders';
  public static readonly GET_CONTACTORDER: String = AppConstants.HOST_IP + '/orders';


  public static readonly GETALL_ORDEREMAILS: String = AppConstants.HOST_IP + '/orders';
  public static readonly DELETE_ORDEREMAILS: String = AppConstants.HOST_IP + '/orders';
  public static readonly CREATE_ORDEREMAILS: String = AppConstants.HOST_IP + '/orders';


  //orders comments
  public static readonly GETALL_ORDERCOMMENTS: String = AppConstants.HOST_IP + '/ordercomments/getAll';
  public static readonly GET_ORDERCOMMENT: String = AppConstants.HOST_IP + '/ordercomments';
  public static readonly CREATE_ORDERCOMMENT: String = AppConstants.HOST_IP + '/ordercomments/create';
  public static readonly UPDATE_ORDERCOMMENT: String = AppConstants.HOST_IP + '/order/update';
  public static readonly DELETE_ORDERCOMMENT: String = AppConstants.HOST_IP + '/order/delete';
  public static readonly DELETE_ORDERCOMMENTIMAGE: String = AppConstants.HOST_IP + '/ordercommentimages/delete';

  // marketing
  public static readonly SEND_MARKETING_MAIL: String = AppConstants.HOST_IP + '/marketing/email';
  public static readonly CSV_FILE_UPLOAD: String = AppConstants.HOST_IP + '/marketing/email';
  public static readonly EMAIL_MARKETING_GROUP: String = AppConstants.HOST_IP + '/marketing/emailgroups';
  public static readonly FORMS_MARKETING: String = AppConstants.HOST_IP + '/marketing/forms';
  public static readonly LANDINGPAGES_MARKETING: String = AppConstants.HOST_IP + '/marketing/landingpage';
  public static readonly LANDINGPAGESTEMPLATES: String = AppConstants.HOST_IP + '/marketing';
  public static readonly ALL_LANDINGPAGES_MARKETING: String = AppConstants.HOST_IP + '/marketing/landingpage/getLandingPages';
  public static readonly IMPORT_EMAIL_MARKETING_GROUP: String = AppConstants.HOST_IP + '/import';
  public static readonly MARKETING_FOLDERS: String = AppConstants.HOST_IP + '/marketing/folders';
  public static readonly MARKETING_SEO: String = AppConstants.HOST_IP + '/marketing/seo';
  public static readonly GET_SCHEDULE_EMAIL: String = AppConstants.HOST_IP + '/marketing/email';
  public static readonly MARKETING_MAIL: String = AppConstants.HOST_IP + '/marketing/email';



  // campaign
  public static readonly CREATE_CAMPAIGN: String = AppConstants.HOST_IP + '/campaigns';
  public static readonly GETALL_CAMPAIGNS: String = AppConstants.HOST_IP + '/campaigns';
  public static readonly GET_CAMPAIGN: String = AppConstants.HOST_IP + '/campaigns';
  public static readonly UPDATE_CAMPAIGN: String = AppConstants.HOST_IP + '/campaigns';
  public static readonly DELETE_CAMPAIGN: String = AppConstants.HOST_IP + '/campaigns';

  // campaigntasks
  public static readonly CREATE_CAMPAIGNTASK: String = AppConstants.HOST_IP + '/campaigns';
  public static readonly GETALL_CAMPAIGNTASKS: String = AppConstants.HOST_IP + '/campaigns';
  public static readonly GET_CAMPAIGNTASKS: String = AppConstants.HOST_IP + '/campaigns';
  public static readonly UPDATE_CAMPAIGNTASK: String = AppConstants.HOST_IP + '/campaigns';
  public static readonly DELETE_CAMPAIGNTASK: String = AppConstants.HOST_IP + '/campaigns';

  // campaignevents
  public static readonly CREATE_CAMPAIGNEVENT: String = AppConstants.HOST_IP + '/campaigns';
  public static readonly GET_CAMPAIGNEVENTS: String = AppConstants.HOST_IP + '/campaigns';
  public static readonly GETALL_CAMPAIGNEVENTS: String = AppConstants.HOST_IP + '/campaigns';
  public static readonly UPDATE_CAMPAIGNEVENT: String = AppConstants.HOST_IP + '/campaigns';
  public static readonly DELETE_CAMPAIGNEVENT: String = AppConstants.HOST_IP + '/campaigns';


  // campaignfiles
  public static readonly CREATE_CAMPAIGNFILE: String = AppConstants.HOST_IP + '/campaigns';
  public static readonly GETALL_CAMPAIGNFILES: String = AppConstants.HOST_IP + '/campaigns';
  public static readonly GET_CAMPAIGNFILE: String = AppConstants.HOST_IP + '/campaigns';
  public static readonly DELETE_CAMPAIGNFILE: String = AppConstants.HOST_IP + '/campaigns';

  // campaignnotes
  public static readonly CREATE_CAMPAIGNNOTE: String = AppConstants.HOST_IP + '/campaigns';
  public static readonly GET_CAMPAIGNNOTE: String = AppConstants.HOST_IP + '/campaigns';
  public static readonly GETALL_CAMPAIGNNOTES: String = AppConstants.HOST_IP + '/campaigns';
  public static readonly UPDATE_CAMPAIGNNOTE: String = AppConstants.HOST_IP + '/campaigns';
  public static readonly DELETE_CAMPAIGNNOTE: String = AppConstants.HOST_IP + '/campaigns';

  // campaignactivities
  public static readonly GETALL_CAMPAIGNACTIVITY: String = AppConstants.HOST_IP + '/campaigns';
  public static readonly CREATE_CAMPAIGNACTIVITY: String = AppConstants.HOST_IP + '/campaigns';


  // campaignemails
  public static readonly CREATE_CAMPAIGNEMAIL: String = AppConstants.HOST_IP + '/campaigns';
  public static readonly GETALL_CAMPAIGNEMAILS: String = AppConstants.HOST_IP + '/campaigns';
  public static readonly UPDATE_CAMPAIGNEMAIL: String = AppConstants.HOST_IP + '/campaigns';
  public static readonly GET_CAMPAIGNEMAILS: String = AppConstants.HOST_IP + '/campaigns';
  public static readonly DELETE_CAMPAIGNEMAILS: String = AppConstants.HOST_IP + '/campaigns';

  // campaignsocial
  public static readonly CREATE_CAMPAIGNSOCIAL: String = AppConstants.HOST_IP + '/campaigns';
  public static readonly GETALL_CAMPAIGNSOCIAL: String = AppConstants.HOST_IP + '/campaigns';
  public static readonly UPDATE_CAMPAIGNSOCIAL: String = AppConstants.HOST_IP + '/campaigns';
  public static readonly GET_CAMPAIGNSOCIAL: String = AppConstants.HOST_IP + '/campaigns';

  // campaignlanding
  public static readonly CREATE_CAMPAIGNLANDING: String = AppConstants.HOST_IP + '/campaigns';
  public static readonly GETALL_CAMPAIGNLANDING: String = AppConstants.HOST_IP + '/campaigns';
  public static readonly UPDATE_CAMPAIGNLANDING: String = AppConstants.HOST_IP + '/campaigns';

  // campaignContacts
  public static readonly CREATE_CAMPAIGNCONTACT: String = AppConstants.HOST_IP + '/campaigns';
  public static readonly GETALL_CAMPAIGNCONTACTS: String = AppConstants.HOST_IP + '/campaigns';


  //tasks
  // public static readonly CREATE_TASK: String = AppConstants.HOST_IP + '/tasks';

  //  Product Pricing
  public static readonly GETALL_PACKAGES: string = AppConstants.HOST_IP + '/risepathproducts/packages';
  //  SUBSCRIPTION DETAILS
  public static readonly GETALL_SUBSCRIPTIONS: string = AppConstants.HOST_IP + '/subscriptiondetails/';
  public static readonly SUBSCRIPTIONSDETAIL: string = AppConstants.HOST_IP + '/subscriptiondetails/detail';
  public static readonly SUBSCRIPTIONINVOICEDETAIL: string = AppConstants.HOST_IP + '/subscriptiondetails/invoice-detail';
  public static readonly ADDUSERTOSUBSCRIPTION: string = AppConstants.HOST_IP + '/subscriptiondetails/add-user';
  public static readonly CANCELSUBSCRIPTION: string = AppConstants.HOST_IP + '/subscriptiondetails/cancel-subscription';

  // groupcontacts
  public static readonly CREATE_GROUPCONTACT: String = AppConstants.HOST_IP + '/groups';
  public static readonly GETALL_GROUPCONTACT: String = AppConstants.HOST_IP + '/groups';
  public static readonly GET_GROUPCONTACT: String = AppConstants.HOST_IP + '/groups';

  // groupdeals
  public static readonly CREATE_GROUPDEALS: String = AppConstants.HOST_IP + '/groups';
  public static readonly GETALL_GROUPDEALS: String = AppConstants.HOST_IP + '/groups';

  // groupusers
  public static readonly CREATE_GROUPUSERS: String = AppConstants.HOST_IP + '/groups';
  public static readonly GETALL_GROUPUSERS: String = AppConstants.HOST_IP + '/groups';

  //gmailcontacts
  public static readonly GMAILCONNECT: String = AppConstants.HOST_IP + '/gmailconnect';
  public static readonly GMAILMEETINGCONNECT: String = AppConstants.HOST_IP + '/gmailconnect';
  public static readonly ORGGMAILCONNECT: String = AppConstants.HOST_IP + '/gmailconnect';
  public static readonly UPDATEORGGMAILCONNECT: String = AppConstants.HOST_IP + '/gmailconnect';
  public static readonly DEALGMAILCONNECT: String = AppConstants.HOST_IP + '/gmailconnect';
  public static readonly TICKETGMAILCONNECT: String = AppConstants.HOST_IP + '/gmailconnect';
  public static readonly ORDERGMAILCONNECT: String = AppConstants.HOST_IP + '/gmailconnect';



  //googleCalendar
  public static readonly GOOGLECALENDAR: String = AppConstants.HOST_IP + '/googlecalendar';

  //contactcalendars
  public static readonly GETALL_CALENDAR_EMAILS: String = AppConstants.HOST_IP + '/contacts';

  // pinterest
  public static readonly GETALL_MARKETINGPINTERESTACCOUNTS: String = AppConstants.HOST_IP + "/marketing/pinterest";
  public static readonly POST_PINTERESTCONTENT: String = AppConstants.HOST_IP + "/pinterest";
  public static readonly POST_PINTERESTBOARD: String = AppConstants.HOST_IP + "/pinterest";
  public static readonly GET_PINTERESTCONTENT: String = AppConstants.HOST_IP + "/pinterest";

  //tiktok
  public static readonly GETALL_MARKETINGTIKTOKACCOUNTS: String = AppConstants.HOST_IP + "/marketing/tiktok";
  public static readonly POST_TIKTOKCONTENT: String = AppConstants.HOST_IP + "/tiktok";

  //Teams
  public static readonly GETALL_MARKETINGTEAMSACCOUNTS: String = AppConstants.HOST_IP + "/marketing/teams";
  public static readonly POST_TEAMSCONTENT: String = AppConstants.HOST_IP + "/teams";
  public static readonly GET_TEAMSCONTENT: String = AppConstants.HOST_IP + "/teams";

  //slack
  public static readonly GETALL_MARKETINGSLACKACCOUNTS: String = AppConstants.HOST_IP + "/marketing/slack";
  public static readonly POST_SLACKCONTENT: String = AppConstants.HOST_IP + "/slack";
  public static readonly GET_SLACKCONTENT: String = AppConstants.HOST_IP + "/slack";

  //usermeetings
  public static readonly CREATE_USERMEETING: String = AppConstants.HOST_IP + '/users';
  public static readonly GETALL_USERMEETINGS: String = AppConstants.HOST_IP + '/users';
  public static readonly GET_USERMEETING: String = AppConstants.HOST_IP + '/users';
  public static readonly DELETE_USERMEETING: String = AppConstants.HOST_IP + '/users';
  public static readonly UPDATE_USERMEETING: String = AppConstants.HOST_IP + '/users';

  //usermeetings outlook
  public static readonly GET_OUTLOOK: String = AppConstants.HOST_IP + '/outlook';
  public static readonly OUTLOOKCONNECT: String = AppConstants.HOST_IP + '/outlook';

  //support
  public static readonly CREATE_SUPPORT: String = AppConstants.HOST_IP + '/support';

  public static readonly SHOWALL_PIPELINES: String = AppConstants.HOST_IP + '/pipelines/getpipelines';

  public static readonly SOCIALMEDIA_OVERALL: String = AppConstants.HOST_IP + '/socialmediaanalytics';

  // ticketPipeline
  public static readonly CREATE_TICKETPIPELINE: String = AppConstants.HOST_IP + '/tickets/pipeline';
  public static readonly GETALL_TICKETPIPELINES: String = AppConstants.HOST_IP + '/tickets/pipeline';
  public static readonly GET_TICKETPIPELINE: String = AppConstants.HOST_IP + '/tickets/pipeline';
  public static readonly UPDATE_TICKETPIPELINE: String = AppConstants.HOST_IP + '/tickets/pipeline';
  public static readonly DELETE_TICKETPIPELINE: String = AppConstants.HOST_IP + '/tickets/pipeline';

  // ticketpipelinestages
  public static readonly CREATE_TICKETPIPELINESTAGE: String = AppConstants.HOST_IP + '/tickets/pipeline';
  public static readonly GETALL_TICKETPIPELINESTAGES: String = AppConstants.HOST_IP + '/tickets/pipeline';
  public static readonly UPDATE_TICKETPIPELINESTAGE: String = AppConstants.HOST_IP + '/tickets/pipeline';
  public static readonly DELETE_TICKETPIPELINESTAGE: String = AppConstants.HOST_IP + '/tickets/pipeline';

  public static readonly EMAIL_DELIVERY_DETAILS: String = AppConstants.HOST_IP + '/marketing/sendgrid';
  public static readonly EMAIL_OPEN_DETAILS: String = AppConstants.HOST_IP + '/marketing/sendgrid';
  public static readonly EMAIL_BOUNCE_DETAILS: String = AppConstants.HOST_IP + '/marketing/sendgrid';
  public static readonly EMAIL_PROCESSED_DETAILS: String = AppConstants.HOST_IP + '/marketing/sendgrid';
  public static readonly EMAIL_UNSUBSCRIBE_DETAILS: String = AppConstants.HOST_IP + '/marketing/sendgrid';
  public static readonly EMAIL_DROPPED_DETAILS: String = AppConstants.HOST_IP + '/marketing/sendgrid';
  public static readonly EMAIL_SPAMREPORTS_DETAILS: String = AppConstants.HOST_IP + '/marketing/sendgrid';

  // sequence
  public static readonly CREATE_SEQUENCE: String = AppConstants.HOST_IP + '/automation/sequence';
  public static readonly GETALL_SEQUENCE: String = AppConstants.HOST_IP + '/automation/sequence';
  public static readonly GET_SEQUENCE: String = AppConstants.HOST_IP + '/automation/sequence';
  public static readonly CREATE_WORKFLOW_SEQUENCE: String = AppConstants.HOST_IP + '/automation/sequence';
  public static readonly GET_WORKFLOW_SEQUENCE: String = AppConstants.HOST_IP + '/automation/sequence';
  public static readonly UPDATE_WORKFLOW_SEQUENCE: String = AppConstants.HOST_IP + '/automation/sequence';

  // sequenceTaks
  public static readonly CREATE_SEQUENCE_TASK: String = AppConstants.HOST_IP + '/automation/sequence';
  public static readonly GET_SEQUENCE_TASK: String = AppConstants.HOST_IP + '/automation/sequence';

  // sequenceContact
  public static readonly CREATE_SEQUENCE_CONTACT: String = AppConstants.HOST_IP + '/automation/sequence';
  public static readonly GET_SEQUENCE_CONTACT: String = AppConstants.HOST_IP + '/automation/sequence';

  // workflow

  public static readonly CREATE_WORKFLOW: String = AppConstants.HOST_IP + '/automation/workflow';
  public static readonly GETALL_WORKFLOW: String = AppConstants.HOST_IP + '/automation/workflow';
  public static readonly GET_WORKFLOW: String = AppConstants.HOST_IP + '/automation/workflow';
  public static readonly UPDATE_WORKFLOW: String = AppConstants.HOST_IP + '/automation/workflow';
  public static readonly CREATE_WORKFLOW_FILTER: String = AppConstants.HOST_IP + '/automation/workflow';
  public static readonly GET_WORKFLOW_FILTER: String = AppConstants.HOST_IP + '/automation/workflow';

  // workflowtasks
  public static readonly CREATE_WORKFLOW_TASK: String = AppConstants.HOST_IP + '/automation/workflow';
  public static readonly GET_WORKFLOW_TASK: String = AppConstants.HOST_IP + '/automation/workflow';

  // workflowContacts
  public static readonly CREATE_WORKFLOW_CONTACT: String = AppConstants.HOST_IP + '/automation/workflow';
  public static readonly GET_WORKFLOW_CONTACT: String = AppConstants.HOST_IP + '/automation/workflow';

  // sequenceEmail 
  public static readonly GET_SEQUENCE_EMAIL: String = AppConstants.HOST_IP + '/automation/sequence';
  public static readonly CREATE_SEQUENCE_EMAIL: String = AppConstants.HOST_IP + '/automation/sequence';

  // workflowEmail
  public static readonly GET_WORKFLOW_EMAIL: String = AppConstants.HOST_IP + '/automation/workflow';
  public static readonly CREATE_WORKFLOW_EMAIL: String = AppConstants.HOST_IP + '/automation/workflow';

  // teamcontacts
  public static readonly CREATE_TEAMCONTACT: String = AppConstants.HOST_IP + '/userteams';
  public static readonly GETALL_TEAMCONTACT: String = AppConstants.HOST_IP + '/userteams';
  public static readonly GET_TEAMCONTACT: String = AppConstants.HOST_IP + '/userteams';

  // teamdeals
  public static readonly CREATE_TEAMDEALS: String = AppConstants.HOST_IP + '/userteams';
  public static readonly GETALL_TEAMDEALS: String = AppConstants.HOST_IP + '/userteams';
  public static readonly GET_TEAMDEALS: String = AppConstants.HOST_IP + '/userteams';

  // teamusers
  public static readonly CREATE_TEAMUSERS: String = AppConstants.HOST_IP + '/userteams';
  public static readonly GETALL_TEAMUSERS: String = AppConstants.HOST_IP + '/userteams';
  public static readonly GET_TEAMUSERS: String = AppConstants.HOST_IP + '/userteams';



  //useraccess
  public static readonly GETALL_USERACCESS: String = AppConstants.HOST_IP + '/users';
  public static readonly UPDATE_ACCESS: String = AppConstants.HOST_IP + '/users';
  public static readonly GET_USERACCESS: String = AppConstants.HOST_IP + '/users';
  public static readonly CREATE_USERACCESS: String = AppConstants.HOST_IP + '/users';
  public static readonly DELETE_USERACCESS: String = AppConstants.HOST_IP + '/users';

  //backlinks
  public static readonly GETALL_BACKLINKS: String = AppConstants.HOST_IP + '/backlinkchecker';
  public static readonly CREATE_BACKLINKS: String = AppConstants.HOST_IP + '/backlinkchecker';
  public static readonly GET_BACKLINKS: String = AppConstants.HOST_IP + '/backlinkchecker';
  public static readonly DELETE_BACKLINKS: String = AppConstants.HOST_IP + '/backlinkchecker';

  //keywordsearch
  public static readonly GETALL_KEYWORDS: String = AppConstants.HOST_IP + '/keywordresearch';
  public static readonly CREATE_KEYWORDS: String = AppConstants.HOST_IP + '/keywordresearch';
  public static readonly GET_KEYWORDS: String = AppConstants.HOST_IP + '/keywordresearch';
  public static readonly DELETE_KEYWORDS: String = AppConstants.HOST_IP + '/keywordresearch';

  //websiteaudit
  public static readonly GETALL_WEBSITEAUDITS: String = AppConstants.HOST_IP + '/websiteaudit';
  public static readonly CREATE_WEBSITEAUDITS: String = AppConstants.HOST_IP + '/websiteaudit';
  public static readonly GET_WEBSITEAUDITS: String = AppConstants.HOST_IP + '/websiteaudit';
  public static readonly DELETE_WEBSITEAUDITS: String = AppConstants.HOST_IP + '/websiteaudit';

  //youtube
  public static readonly YOUTUBE: String = AppConstants.HOST_IP + '/marketing/youtubeconnect';
  public static readonly GETALL_YOUTUBE: String = AppConstants.HOST_IP + '/marketing/youtubeconnect';
  public static readonly POST_YOUTUBE_VIDEO: String = AppConstants.HOST_IP + '/marketing/youtubeconnect';

}