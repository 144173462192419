import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, filter, switchMap } from 'rxjs/operators';
import { AppConstants } from '../utilities/AppConstants';
import { CookieService } from 'ngx-cookie-service';

@Injectable({ providedIn: 'root' })
export class UserAccessService {

    userAccessDetailInfo = new BehaviorSubject<any>(null);

    constructor(public http: HttpClient, public cookieService: CookieService) { }

    getAllUserAccess(userId, page, limit, type): Observable<any> {
        const getAllTeamsUrl = AppConstants.GETALL_USERACCESS + '/' + userId + '/access?page='+page+'&limit='+limit+'&type='+type;
        return this.http
            .get(getAllTeamsUrl, {})
            .pipe(map(
                res => {
                    return res;
                },
                err => {
                    return err;
                }
            ));
    }

    updateAcess(data): Observable<any> {
        
        const URL = AppConstants.UPDATE_ACCESS + '/' + data.userId + '/access/' + data.id
        console.log(URL);
        
        return this.http
            .put(URL, data)
            .pipe(map(
                res => {
                    return res;
                },
                err => {
                    return err;
                }
            ));
    }

    getAllUserAccessTools(userId, page, limit, type): Observable<any> {
        const getAllTeamsUrl = AppConstants.GETALL_USERACCESS + '/' + userId + '/tools?page='+page+'&limit='+limit+'&type='+type;
        return this.http
            .get(getAllTeamsUrl, {})
            .pipe(map(
                res => {
                    return res;
                },
                err => {
                    return err;
                }
            ));
    }

    getAllUserAccessQuery(userId): Observable<any> {
        const getAllTeamsUrl = AppConstants.GETALL_USERACCESS + '/' + userId + '/access';
        return this.http
            .get(getAllTeamsUrl, {})
            .pipe(map(
                res => {
                    return res;
                },
                err => {
                    return err;
                }
            ));
    }

    updateCrmAcess(data): Observable<any> {
        
        const URL = AppConstants.UPDATE_ACCESS + '/' + data.userId + '/tools/' + data.id
        console.log(URL);
        
        return this.http
            .put(URL, data)
            .pipe(map(
                res => {
                    return res;
                },
                err => {
                    return err;
                }
            ));
    }

    getUserAccess(userId, subType):Observable<any> {
        const URL = AppConstants.GET_USERACCESS + '/' + userId + '/userAccess' +'/' + subType;
        console.log('Url', URL);
        return this.http
            .get(URL, {})            
            .pipe(map(
                res => {
                    return res;
                },
                err => {
                    return err;
                }
            ));
    }

    createUserAccess(data):Observable<any> {
        const URL = `${ AppConstants.CREATE_USERACCESS }/${ data.userId }/access`;
        
        return this.http
            .post(URL, data)
            .pipe(map(
                res => {
                    return res;
                },
                err => {
                    return err;
                }
            ));
    }

    deleteUserAccess(data):Observable<any> {
        const URL = `${AppConstants.DELETE_USERACCESS}/${data.userId}/access/${data.id}`;
        
        return this.http
            .delete(URL, data)
            .pipe(map(
                res => {
                    return res;
                },
                err => {
                    return err;
                }
            ));
    }
}