import { Component, OnInit, OnDestroy, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { pagesToggleService } from '../../services/toggler.service';
import { QuickviewService } from './quickview.service';
import { Note } from './note';
import { chatMessage, chatHistory } from './message';
import { UserService } from '../../services/user.service';
import { AlertService } from '../../services/alert.service';
import { Router } from '@angular/router';
import { UserNoteService } from '../../services/usernotes.service';
import { ActivityReportsService } from '../../services/activityreports.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { UntypedFormGroup,UntypedFormControl,Validators,UntypedFormBuilder } from '@angular/forms';
@Component({
  selector: 'app-quickview',
  templateUrl: './quickview.component.html',
  styleUrls: ['./quickview.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class QuickviewComponent implements OnInit, OnDestroy {

  constructor(private _service: QuickviewService,private fb: UntypedFormBuilder, private alert: AlertService, private userService: UserService, private http: HttpClient,private activityReportService: ActivityReportsService, private toggler: pagesToggleService, public router: Router, public userNoteService: UserNoteService) {
    this.subscriptions.push(
      this.toggler.quickViewToggle.subscribe(message => {
        this.toggle();
      })
    );
  }
  subscriptions: Array<Subscription> = [];
  isOpen: boolean = false;
  noteList = [];
  noteDeleteList = [];

  usersList: string[];
  usersMap: any;
  tenantId: any;
  userProfilePicturePath;
  //Single
  selectedNote: Note;
  noteText = '';
  //List for deleting or CRUD functions
  deleteNoteMode: boolean = false;
  isNoteOpen = false;
  profilePictureDetails;
  userList: any = [];
  chatHistory: chatHistory;
  userMessage;
  newMessage: chatMessage;
  editorModules = {
    //https://github.com/KillerCodeMonkey/ngx-quill
    toolbar: [[{ header: [1, 2, 3, 4, false] }], ['bold', 'italic', 'underline'], ['link', 'image']]
  };
  @ViewChild('chatHistoryWrapper', { static: true }) chatHistoryWrapper: ElementRef;
  @ViewChild('mdSlideUp', { static: true }) mdSlideUp: ModalDirective;
  slideUp: any = {
    type: 'md'
  };
  stickUp: any = {
    type: 'md'
  };
  page = 1;
  limit = 100;
  userDetails;
  userId;
  activityReports;
  noteAlertList = []
  taskAlertList = []
  eventAlertList = []
  fileAlertList = []
  url: any;
  userForm: UntypedFormGroup;
  userFinalList = []
  noteId;
  showSlideUp() { 
    switch (this.slideUp.type) {
      case 'md': {
        this.mdSlideUp.show();
        break;
      }

    }
  }

  _submitForm() {

    if(!this.userForm.valid) {
      this.alert.error('Invalid data. Please verify');
      return;
    }
    this.mdSlideUp.hide();
    // if(registerForm.value.txtpassword != registerForm.value.txtrepeatPassword) {
    //   this.alert.error('Passwords are not matching. Please verify.');
    //   return;
    // }

    const registerFormData = { firstName: '', lastName: '', email: '', tenantId:'',lastAccessedPipelineId:'', companyName: '', phoneNumber: '', password: '',createdBy: 'admin@risepath.com', updatedBy: 'admin@risepath.com'};
    registerFormData.firstName = this.userForm.value.txtfname;
    registerFormData.lastName = this.userForm.value.txtlname;
    registerFormData.email = this.userForm.value.txtemail;
    registerFormData.phoneNumber = this.userForm.value.txtphoneNumber;
   // registerFormData.password = registerForm.value.txtpassword;
    registerFormData.tenantId = this.tenantId;
    registerFormData.companyName = localStorage.getItem('companyName');
    registerFormData.lastAccessedPipelineId = '0';
    registerFormData.createdBy =  this.userId ?  this.userId : 'admin@risepath.com';
    registerFormData.updatedBy =  this.userId ?  this.userId : 'admin@risepath.com';
    this.userService.createUser(registerFormData).subscribe(res => {
      this.userForm.reset();
     
      this.getChatlist()
      this.alert.success('User created successfully.');
    },
    err => {
    // userForm.form.reset();  
      if(err.message) {
        if(err.message === 'read ECONNRESET' ) {
          this.alert.error('Error occured: ' + 'Connection Timeout. Please try again');
        } else {
          this.alert.error('Error occured: ' + err.message);
        }
      } else if(err.error) {
           if(err.error.message) {
              this.alert.error('Error occured: ' + err.error.message);
        }
      } else {
        this.alert.error('Error occured: ' + err);
      }
    });

    
  }

  getActivityReports(page, limit, tenantId) {

    this.activityReportService.getAllActivityReports(page, limit).subscribe(res => {

      this.activityReports = res.count;
    
      this.getNoteAlerts();
      this.getTaskAlerts();
      this.getEventAlerts();
      this.getFileAlerts();
    },
    err => {
       this.alert.error('Error occured: '+err.error.message);
    });
  }
  getNoteAlerts() {
    for(const item of this.activityReports) {

      
      if(this.noteAlertList.length > 2) {
        break;
      }
      if(item.type ==='NOTE') {
        this.noteAlertList.push(item);
      } 
    }
  }

  getTaskAlerts() {
    for(const item of this.activityReports) {
      if(this.taskAlertList.length > 2) {
        break;
      }
      if(item.type ==='TASK') {
        this.taskAlertList.push(item);
      } 
    }
  }

  getEventAlerts() {
    for(const item of this.activityReports) {
      if(this.eventAlertList.length > 2) {
        break;
      }
      if(item.type ==='EVENT') {
        this.eventAlertList.push(item);
      } 
    }
  }

  getFileAlerts() {
    for(const item of this.activityReports) {
      if(this.fileAlertList.length > 2) {
        break;
      }
      if(item.type ==='FILE') {
        this.fileAlertList.push(item);
      } 
    }
  }
  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }

  public noWhitespaceValidator(control: UntypedFormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  ngOnInit() {
    this.userForm = this.fb.group({
      txtfname: ['',[Validators.required, this.noWhitespaceValidator]],
      txtlname: ['',[Validators.required, this.noWhitespaceValidator]],
      txtemail: ['',[Validators.required, this.noWhitespaceValidator]],
      txtphoneNumber: ['', [this.noWhitespaceValidator, Validators.pattern('[- +()0-9]+')]],
    })
    this.tenantId = localStorage.getItem('tenantId');
    this.userDetails = JSON.parse(localStorage.getItem('currentUser'));
    this.userId = this.userDetails.id;
    this.tenantId = localStorage.getItem('tenantId');
    this.profilePictureDetails = this.userDetails.profilePictureDetails;
    const parsedUrl = new URL(window.location.href);
    const baseUrl = parsedUrl.origin;
    this.url = baseUrl;
  //  if(this.profilePictureDetails) {
  //   this.userProfilePicturePath = baseUrl + this.profilePictureDetails.path;
  //  }
    this.userId = this.userDetails.id;
   
    const item = JSON.parse(localStorage.getItem('currentUser'));
    // this.getChatlist();
    // this.getUserNotes();
    // this.getActivityReports(this.page, this.limit, this.tenantId);
  }
  getChatlist() {
    this.userService.chatList(localStorage.getItem('tenantId')).subscribe(res => {
  
    
      
      this.userList = res;
    },
      err => {
        this.alert.error('Error occured: ' + err);
      });
  }


  goToUserPage(user) {
   
    const userId = this.userList[0].usersMap[user.username]
    this.router.navigate(['/users/details'], { queryParams: { userId: userId } });
    if (this.isOpen) {
      this.isOpen = false;
    } else {
      this.isOpen = true;
    }

  }
  toggle() {
    if (this.isOpen) {
      this.isOpen = false;
    } else {
      this.isOpen = true;
    }
  }


  getUserNotes() {
    this.userNoteService.getAllUserNotes(this.page, this.limit, this.userId).subscribe(res => {

      this.noteList = res.userNote;

    },
      err => {
        this.alert.error('Error occured: ' + err);
      });
  }
  popNote(item: Note): void {
    const index = this.noteDeleteList.indexOf(item);
    if (index !== -1) {
      this.noteDeleteList.splice(index, 1);
    }
  }

  pushNote(item: Note): void {
    this.noteDeleteList.push(item);
  }
  onSelectNote(item): void {
    if (!this.deleteNoteMode) {
      this.selectedNote = item;
      this.noteText = item.name;
      this.noteId = item.id;
      this.isNoteOpen = true;
    }
  }
  toggleNotesView(): void {
    if (this.isNoteOpen) {
      this.isNoteOpen = false;
     // this.saveNote();
    } else this.isNoteOpen = true;
  }

  onCheck(e, item): void {
    
    // if (e.target.checked) {
    //   this.pushNote(item);
    // } else {
    //   this.popNote(item);
    // }
  }

 

  composeNote(): void {

     this.isNoteOpen = true;
     this.selectedNote = new Note();
    //  this.selectedNote.id = this.noteList.length + 1;
    //  this.selectedNote.date = new Date();
    //  this.selectedNote.notes = '';
    //  this.noteText = '';
    // this.noteList.push(this.selectedNote);
  }

  saveNote() {
   
    const val = this.noteText.length-4
    const data = {
      userId: this.userId,
      userNoteId: this.noteId,
      name: this.noteText.substring(3,Number(val)),
      tenantId: this.tenantId,
      createdBy: this.userId,
      updatedBy: this.userId
    }
    if(data.name.length === 0) {
      this.alert.error('Please enter a valid note and save.')
    }
    this.userNoteService.updateUserNote(data).subscribe(res => {
      
      this.alert.success('Note Saved Successfully');
      this.getUserNotes();
      this.noteText = '';
      //this.noteList = res.userNote;

    },
      err => {
        this.alert.error('Error occured: ' + err);
      });
    
  }

  deleteMode(): void {
    if (this.deleteNoteMode) this.deleteNoteMode = false;
    else this.deleteNoteMode = true;
  }

  deleteNote(): void {
    this.noteList = this.noteList.filter(item => this.noteDeleteList.indexOf(item) === -1);
  }

  onMessageKeyPress(event) {
    if (event.keyCode === 13) {
      if (this.userMessage) {
        this.newMessage = new chatMessage();
        this.newMessage.from = 'me';
        this.newMessage.date = '';
        this.newMessage.message = this.userMessage;
        this.chatHistory['log'].push(this.newMessage);
        this.userMessage = '';
        this.chatHistoryWrapper.nativeElement.scrollTop = this.chatHistoryWrapper.nativeElement.scrollHeight;
      }
    }
  }
}
