import { Routes } from '@angular/router';
//Layouts
import { CondensedComponent, BlankComponent, ExecutiveComponent } from './@pages/layouts';

export const AppRoutes: Routes = [
  {
    path: '',
    data: {
      breadcrumb: 'Home',
    },
    component: BlankComponent,
    children: [
      {
        path: '',
        redirectTo: 'marketing-crm-software',
        pathMatch: 'full',
      },
      {
        //component: CondensedComponent,
        path: 'landing',
        loadChildren: () => import('./@pages/modules/homepages/landing/landing.module').then(m => m.LandingModule),
      },
      {
        path: 'help',
        loadChildren: () => import('./@pages/modules/homepages/help/help.module').then(m => m.HelpModule),
      },
      {
        // component: SalesRisemainComponent,
        path: 'sales-management-crm-software',
        loadChildren: () => import('./@pages/modules/homepages/salesrise/salesrise.module').then(m => m.SalesRiseModule),
      },
      {
        // component: SalesRisemainComponent,
        path: 'business-crm-software',
        loadChildren: () => import('./@pages/modules/homepages/teamRise/teamrise.module').then(m => m.TeamriseModule),
      },
      {
        // component: SalesRisemainComponent,
        path: 'marketing-crm-software',
        loadChildren: () => import('./@pages/modules/homepages/marketRise/marketrise.module').then(m => m.MarketriseModule),
      },
      {
        // component: SalesRisemainComponent,
        path: 'hr-management-recruiting-software',
        loadChildren: () => import('./@pages/modules/homepages/hrteamRise/hrteamrise.module').then(m => m.HrteamriseModule),
      },
      {
        // component: SalesRisemainComponent,
        path: 'project-management-software-tool',
        loadChildren: () => import('./@pages/modules/homepages/plancentral/plancentral.module').then(m => m.PlancentralModule),
      },
      {
        // component: SalesRisemainComponent,
        path: 'customer-service-support-software',
        loadChildren: () => import('./@pages/modules/homepages/supportRise/supportrise.module').then(m => m.SupportriseModule),
      },
      {
        path: 'checkout',
        loadChildren: () => import('./@pages/modules/homepages/checkout/checkout.module').then(m => m.CheckoutModule),
      },
      {
        component: ExecutiveComponent,
        path: 'users',
        loadChildren: () => import('./@pages/modules/settings/users/users.module').then(m => m.UsersModule),
      },
      {
        component: ExecutiveComponent,
        path: 'roles',
        loadChildren: () => import('./@pages/modules/settings/roles/roles.module').then(m => m.RolesModule),
      },
      {
        component: ExecutiveComponent,
        path: 'usernotification', 
        loadChildren: () => import('./@pages/modules/settings/user-notifications/user-notification.module').then(m => m.NotificationModule),
      },
      {
        component: ExecutiveComponent,
        path: 'termsofuse',
        loadChildren: () => import('./@pages/modules/homepages/termsofuse/termsofuse.module').then(m => m.TermsOfUseModule),
      },
      {
        component: ExecutiveComponent,
        path: 'privacypolicy',
        loadChildren: () => import('./@pages/modules/homepages/privacypolicy/privacypolicy.module').then(m => m.PrivacyPolicyModule),
      },
      {
        component: ExecutiveComponent,
        path: 'socialmedia',
        loadChildren: () => import('./@pages/modules/socialmedia/social-media.module').then(m => m.SocialMediaModule),
      },
      {
        component: ExecutiveComponent,
        path: 'usermeetings',
        loadChildren: () => import('./@pages/modules/settings/usermeetings/usermeetings.module').then(m => m.UserMeetingsModule),
      },
      {
        component: ExecutiveComponent,
        path: 'account',
        loadChildren: () => import('./@pages/modules/homepages/support/support.module').then(m => m.SupportModule),
      },
      {
        path: 'usermeetingsschedule',
        loadChildren: () => import('./@pages/modules/settings/usermeetings-schedule/usermeetings-schedule.module').then(m => m.UserMeetingsScheduleModule),
      },
      {
        path: '**',
        redirectTo: 'landing',
        pathMatch: 'full',
      },
    ],
  },
];