import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  HostListener,
  AfterViewInit,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { RootLayout } from '../root/root.component';
import { log } from 'util';
import { Router } from '@angular/router';
import { TemplateRef, NgModule } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { NgForm } from '@angular/forms';

import { AlertService } from '../../services/alert.service';
import { UserService } from '../../services/user.service';
import { TenantDetailService } from '../../services/tenantdetails.service';
import { pagesToggleService } from '../../services/toggler.service';

@Component({
  selector: 'condensed-layout',
  templateUrl: './condensed.component.html',
  styleUrls: ['./condensed.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CondensedComponent extends RootLayout implements OnInit {
  tenantId;
  isLoading;
  page = 1;
  limit = 4;
  userEmail;
  userId;
  today: number = Date.now();
  userDetails: any;
  tenantDetails: any;
  paths: Array<string> = [];
  profilePictureDetails;
  userProfilePicturePath;
  activityReportsList;
  menuLinks = [
    {
      label: 'Dashboard',
      iconType: 'pg',
      iconName: 'home',
      toggle: 'close',
      submenu: [
        {
          label: 'dealsDashBoard',
          routerLink: '/dashboard/details',
        },
        {
          label: 'ticketDashBoard',
          routerLink: '/ticketdashboard/view',
        },
      ],
    },
    {
      label: 'Workspace',
      routerLink: '/workspace/details',
      iconType: 'pg',
      iconName: 'layout',
    },
    {
      label: 'Domains',
      routerLink: '/domain/details',
      iconType: 'pg',
      iconName: 'layout',
    },

    {
      label: 'Contacts',
      iconType: 'pg',
      iconName: 'social',
      toggle: 'close',
      submenu: [
        {
          label: 'People',
          routerLink: '/contacts/list',
        },
        {
          label: 'Organizations',
          routerLink: '/organizations/list',
        },
      ],
    },

    {
      label: 'Tasks',
      iconType: 'pg',
      iconName: 'table',
      toggle: 'close',
      submenu: [
        {
          label: 'Tasks',
          routerLink: '/tasks/list',
        },
      ],
    },
    {
      label: 'Settings',
      iconType: 'pg',
      iconName: 'grid',
      toggle: 'close',
      submenu: [
        {
          label: 'Users',
          routerLink: '/users/list',
        },
        {
          label: 'Roles',
          routerLink: '/roles/list',
        },
        {
          label: 'Billing',
          routerLink: '/billing/wizard',
        },
        {
          label: 'Groups',
          routerLink: '/groups/list',
        },
        {
          label: 'Payments',
          routerLink: '/payments/list',
        },
        {
          label: 'Chat',
          routerLink: '/chat/list',
        },
        {
          label: 'Integrations',
          routerLink: '/integrations/list',
        },
        {
          label: 'Meetings',
          routerLink: '/usermeetings/list',
        },
      ],
    },
    {
      label: 'Marketing',
      iconType: 'pg',
      iconName: 'grid',
      toggle: 'close',
      submenu: [
        {
          label: 'twitter',
          routerLink: '/marketing/twitter',
        },
        {
          label: 'email',
          routerLink: '/marketing/email',
        },
      ],
    },
    {
      label: 'Reports',
      iconType: 'pg',
      iconName: 'flag',
      toggle: 'close',
      submenu: [
        {
          label: 'Activity Reports',
          routerLink: '/activityreports/list',
        },
        {
          label: 'Task Reports',
          routerLink: '/taskreports/list',
        },
        {
          label: 'Event Reports',
          routerLink: '/eventreports/list',
        },
        {
          label: 'Note Reports',
          routerLink: '/notereports/list',
        },
        {
          label: 'File Reports',
          routerLink: '/filereports/list',
        },
      ],
    },
  ];

  ngOnInit() {
    this.userDetails = JSON.parse(localStorage.getItem('currentUser'));
    this.userId = this.userDetails.id;

    this.tenantId = localStorage.getItem('tenantId');
    this.tenantDetails = this.userDetails.tenantDetails;

    this.profilePictureDetails = this.userDetails.profilePictureDetails;

    const parsedUrl = new URL(window.location.href);
    const baseUrl = parsedUrl.origin;

    // if (this.profilePictureDetails) {
    //   this.userProfilePicturePath = baseUrl + this.profilePictureDetails.path;
    // }

    this.userEmail = this.userDetails.email;
    this.menuLinks.forEach((item) => {
      if (item.routerLink) this.paths.push(item.routerLink);
      if (item.submenu) {
        item.submenu.forEach((item) => {
          if (item.routerLink) {
            this.paths.push(item.routerLink);
          }
        });
      }
    });

    if (JSON.parse(this.tenantDetails.isSupportandOrder) || JSON.parse(this.tenantDetails.isTotalCRM)) {
      this.loadSupportAndOrdersLinks();
    }

    if (JSON.parse(this.tenantDetails.isOnlyCRM) || JSON.parse(this.tenantDetails.isTotalCRM)) {
      this.loadOnlyCrmLinks();
    }
    this.getActivityReports(this.page, this.limit, this.tenantId);
    setTimeout(() => {
      this.activityReportsList = this.activityReports;
    }, 6000);
  }

  updateUrl($event) {
    this.userProfilePicturePath = 'assets/home/assets/images/userpic.png';
  }

  goToLogout() {
    localStorage.clear();
    this.router.navigate(['/landing/home']);
  }

  searchKey(key) {
    let path: string;
    for (const item of this.paths) {
      if (item.includes(key)) {
        path = item;
        break;
      }
    }
    if (path === '' || path === undefined) return;
    this.router.navigate([path]);
  }

  loadOnlyCrmLinks() {
    const dealLink = {
      label: 'Deals',
      iconType: 'pg',
      iconName: 'card',
      toggle: 'close',
      submenu: [
        {
          label: 'Deals',
          routerLink: '/deals/view',
        },
        {
          label: 'Pipelines',
          routerLink: '/pipelines/list',
        },
        {
          label: 'Forecast',
          routerLink: '/forecast/details',
        },
      ],
    };
    this.menuLinks.splice(2, 0, dealLink);
  }

  loadSupportAndOrdersLinks() {
    const orderLink = {
      label: 'Orders',
      iconType: 'pg',
      iconName: 'table',
      toggle: 'close',
      submenu: [
        {
          label: 'Orders',
          routerLink: '/orders/list',
        },
      ],
    };

    this.menuLinks.splice(2, 0, orderLink);

    const supportLink = {
      label: 'Support',
      iconType: 'pg',
      iconName: 'clipboard',
      toggle: 'close',
      submenu: [
        {
          label: 'Channels',
          iconType: 'pg',
          iconName: 'effects',
          toggle: 'close',
          routerLink:'',
          submenu: [
            {
              label: 'Email',
              routerLink: '/risepathemail/list',
            },
            {
              label: 'Facebook',
              routerLink: '/risepathfacebook/list',
            },
            {
              label: 'Twitter',
              routerLink: '/risepathtwitter/list',
            },
            {
              label: 'Widgets',
              routerLink: '/widgets/list',
            },
          ],
        },
        {
          label: 'Get Started',
          routerLink: '/account/verifyAccount',
        },
        {
          label: 'Tickets',
          routerLink: '/tickets/list',
        },
      ],
    };
    this.menuLinks.splice(5, 0, supportLink);
  }
}
