import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { map, filter, switchMap } from 'rxjs/operators';
import { AppConstants } from '../utilities/AppConstants';
import { CookieService } from 'ngx-cookie-service';

@Injectable({ providedIn: 'root' })
export class UserService {

    userDetailInfo = new BehaviorSubject<any>(null);

    constructor(public http: HttpClient, public cookieService: CookieService) { }

    getAllUsers(page, limit): Observable<any> {
        
        const getAllUsersUrl = AppConstants.GETALL_USERS  + '?page='+ page +'&limit=' +limit;

        return this.http
            .get(getAllUsersUrl, {})
            .pipe(map(
                res => {
                    return res;
                },
                err => {
                    return err;
                }
            ));
    }

    getFirstSubscribedUser(page, limit): Observable<any> {
        
        const getAllUsersUrl = AppConstants.GETALL_USERS +'/firstuser' + '?page='+ page +'&limit=' +limit ;

        return this.http
            .get(getAllUsersUrl, {})
            .pipe(map(
                res => {
                    return res;
                },
                err => {
                    return err;
                }
            ));
    }

    getAllUsersQuery(page, limit, sortField, sortType, search, viewType): Observable<any> {
        let getAllUsersUrl
        if(search) {
            getAllUsersUrl = AppConstants.GETALL_USERS + '?page='+ page +'&limit=' +limit+'&sortField=' +sortField+'&sortType=' +sortType+'&search=' +search;
        } else {
            getAllUsersUrl = AppConstants.GETALL_USERS + '?page='+ page +'&limit=' +limit+'&sortField=' +sortField+'&sortType=' +sortType;
        }
        if(viewType){
            getAllUsersUrl = AppConstants.GETALL_USERS + '?page='+ page +'&limit=' +limit+'&sortField=' +sortField+'&sortType=' +sortType + '&viewType=' + viewType;
        }
        return this.http
            .get(getAllUsersUrl, {})
            .pipe(map(
                res => {
                    return res;
                },
                err => {
                    return err;
                }
            ));
    }
    validateToken(data): Observable<any> {
        const resetTokenUrl = AppConstants.VALIDATE_USER_TOKEN_URL +"/token/validate/"+ data;
        
        return this.http.post(resetTokenUrl, data)
          .pipe(map(
            res => {
              return res;
            },
            err => {
              return err;
            }
          ));
      }

    allUsers(): Observable<any> { 
        const getAllUsersUrl = AppConstants.ALL_USERS + '';
        return this.http
            .get(getAllUsersUrl, {})
            .pipe(map(
                res => {
                    return res;
                },
                err => {
                    return err;
                }
            ));
    }

    chatList(tenantId): Observable<any> { 
        const getAllUsersUrl = AppConstants.ALL_CHATLIST + '&tenantId=' + tenantId;
        return this.http
            .get(getAllUsersUrl, {})
            .pipe(map(
                res => {
                    return res;
                },
                err => {
                    return err;
                }
            ));
    }

    getUserDetails(userId): Observable<any> {
        const URL = AppConstants.GET_USER + '/' + userId ;
        return this.http
            .get(URL, {})
            .pipe(map(
                res => {
                    return res;
                },
                err => {
                    return err;
                }
            ));
    }

    getLastAccessPipelineId(data):Observable<any> {
        const URL = AppConstants.GET_LASTACCESSPIPELINE + '';
        return this.http
            .post(URL, data)
            .pipe(map(
                res => {
                    return res;
                },
                err => {
                    return err;
                }
            ));
    }

    createUser(data): Observable<any> {
        const URL = AppConstants.CREATE_USER + '';
        return this.http
            .post(URL, data)
            .pipe(map(
                res => {
                    return res;
                },
                err => {
                    return err;
                }
            ));
    }


    sendVerificationMail(data): Observable<any> {
        const URL = AppConstants.SEND_VERIFICATION_MAIL + '/sendverificationmail';
        return this.http
            .post(URL, data)
            .pipe(map(
                res => {
                    return res;
                },
                err => {
                    return err;
                }
            ));
    }

    updateUser(data): Observable<any> {
        const URL = AppConstants.UPDATE_USER + '/' + data.id;
        return this.http
            .put(URL, data)
            .pipe(map(
                res => {
                    return res;
                },
                err => {
                    return err;
                }
            ));
    }

    updateLastAccessPipeline(data): Observable<any> {
        const URL = AppConstants.UPDATE_USERLASTACCESSPIPE + '';
        return this.http
            .put(URL, data)
            .pipe(map(
                res => {
                    return res;
                },
                err => {
                    return err;
                }
            ));
    }

    deleteUser(userId): Observable<any> {
        const URL = AppConstants.DELETE_USER + '/' +userId ;
        return this.http
            .delete(URL, {})
            .pipe(map(
                res => {
                    return res;
                },
                err => {
                    return err;
                }
            ));
    }

}