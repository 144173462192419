import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, filter, switchMap } from 'rxjs/operators';
import { AppConstants } from '../utilities/AppConstants';
import { CookieService } from 'ngx-cookie-service';


@Injectable({ providedIn: 'root' })
export class UserFileService {

    userProfilePic = new BehaviorSubject<any>(null);
    userProfilePicThumb = new BehaviorSubject<any>(null);

    constructor(public http: HttpClient, public cookieService: CookieService) { }

    getAllUserFiles(userId, page, limit): Observable<any> {
        
        const getAllUserFilesUrl = AppConstants.GETALL_USERFILES  +'/'+ userId+'/files?page='+page+'&limit='+limit;
        return this.http
            .get(getAllUserFilesUrl, {})
            .pipe(map(
                res => {
                    return res;
                },
                err => {
                    return err;
                }
            ));
    }

    getUserFileDetails(userFileId): Observable<any> {
        
        const URL = AppConstants.GET_USERFILE + '/' + userFileId+'/filedetails';
        return this.http
            .get(URL, {})
            .pipe(map(
                res => {
                    return res;
                },
                err => {
                    return err;
                }
            ));
    }

    createUserFile(data, userId): Observable<any> {
        // data.tenantId = tenantId;
        const URL = AppConstants.CREATE_USERFILE + '/' + userId +'/files';
        return this.http
            .post(URL, data)
            .pipe(map(
                res => {
                    return res;
                },
                err => {
                    return err;
                }
            ));
    }

    updateUserFile(data): Observable<any> {
        
        const URL = AppConstants.UPDATE_USERFILE + '/' +data.id;
        return this.http
            .put(URL, data)
            .pipe(map(
                res => {
                    return res;
                },
                err => {
                    return err;
                }
            ));
    }

    deleteUserFile(userId): Observable<any> {
        
        const URL = AppConstants.DELETE_USERFILE + '/' +userId + '/files';
        return this.http
            .delete(URL, {})
            .pipe(map(
                res => {
                    return res;
                },
                err => {
                    return err;
                }
            ));
    }

}